import { useState, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { toHHMMSS, getHours, getFullName } from "../../../utils/helper";

import { PageTitle, CommonGrid } from "../../../styledComponents/common";
import {
  TimesheetContainer,
  TotalTimeContainer,
  TimeRangeText,
  TotalTimeDiv,
  TotalTimeLabel,
  TotalTimeText,
  TableContainer,
  TableHeadContainer,
  TableHeadItem,
  TableBodyContainer,
  TableBodyItem,
  DailyTime,
  TimesheetDate,
  DailyTimeWithSheet,
} from "../../../styledComponents/timesheet";

import TimesheetFilter from "../TimesheetFilter";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import RightSlidingPanel from "../../../components/RightSlidingPanel/RightSlidingPanel";
import TimesheetDetails from "../TimesheetDetailsPanel";

import { getRandomColor } from "utils/getRandomColor";

const getTimes = (startDate, endDate) => {
  let times = [];
  while (moment(startDate).isSameOrBefore(moment(endDate))) {
    times.push({
      date: startDate,
      hours: "0 h 0 m",
    });
    startDate = moment(startDate).add(1, "days");
  }
  return times;
};

const CustomRange = ({
  isLoading,

  selectedOrganization,
  profileData,

  clearTimesheetList,
  getOtherTimesheets,
  timesheetsList,

  getDailyTimesheets,
  dailyTimesheetLoading,
  dailyTimesheets,
}) => {
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Active Projects)",
  });
  const [selectedTask, setSelectedTask] = useState({
    value: "",
    label: "(All Tasks)",
  });
  const [currentDate, setCurrentDate] = useState(moment());
  const [startDate, setStartDate] = useState(
    moment().endOf("isoWeek").subtract(13, "days")
  );
  const [endDate, setEndDate] = useState(moment().endOf("isoWeek"));
  const [selectedMember, setSelectedMember] = useState("");

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [timesheetsData, setTimesheetsData] = useState(null);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const [bgColors, setBgColors] = useState({});

  const [activeDate, setActiveDate] = useState(null);
  const [relevantDates, setRelevantDates] = useState([]);
  const [expandedAll, setExpandedAll] = useState(false);
  const [expandedTimesheets, setExpandedTimesheets] = useState([]);

  const [selectedPanelDate, setSelectedPanelDate] = useState(null);

  useEffect(() => {
    if (timesheetsList && timesheetsList.length > 0) {
      const newDates = timesheetsList.map((item) => item.date).sort();

      setRelevantDates(newDates);
    }
  }, [timesheetsList]);

  const disabledNext = useMemo(() => {
    if (moment(selectedPanelDate).isSame(moment(endDate), "day")) {
      return true;
    }

    const hasNextRelevantDate = relevantDates.some((date) =>
      moment(date).isAfter(moment(selectedPanelDate))
    );

    return !hasNextRelevantDate;
  }, [selectedPanelDate, relevantDates, endDate]);

  const disabledPrev = useMemo(() => {
    if (moment(selectedPanelDate).isSame(moment(startDate), "day")) {
      return true;
    }

    const hasPreviousRelevantDate = relevantDates.some((date) =>
      moment(date).isBefore(moment(selectedPanelDate))
    );

    return !hasPreviousRelevantDate;
  }, [selectedPanelDate, relevantDates, startDate]);

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }

      setSelectedProject({ value: null, label: "(All Active Projects)" });
      setSelectedTask({ value: "", label: "(All Tasks)" });
      setSelectedMember("");

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      getOtherTimesheets(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const durations = [];
    const rows = [];
    const times = getTimes(startDate, endDate);
    for (let i = 0; i < Math.ceil(times.length / 7); i++) {
      rows.push(i);
    }
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach((timesheet) => {
        durations.push(timesheet.duration);
        times.some((item) => {
          if (moment(item.date).format("YYYY-MM-DD") === timesheet.date) {
            if (timesheet.duration !== 0) {
              item.hours = getHours(toHHMMSS(timesheet.duration));
            }
            return true;
          }
          return false;
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format("YYYY-MM-DD") === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        rows,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        rows,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  const selectProject = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (selectedMember && e?.value) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === "Member") {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask({ value: "", label: "(All Tasks)" });
    } else if ((e && !e.value) || e == null) {
      setSelectedMember("");
      setSelectedTask({ value: "", label: "(All Tasks)" });
      setSelectedProject(e);
    } else {
      setSelectedProject(e);
    }
  };

  const selectTask = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (typeof selectedProject === "object" && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === "Member") {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
    setSelectedTask(e);
  };

  const selectTeamMember = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedTimezone &&
        selectedTimezone.type === "Member" &&
        e.timezone &&
        e.timezone.tz
      ) {
        payload.timezone = e.timezone.tz;
        setSelectedTimezone({
          type: "Member",
          timezone: e.timezone.tz,
          offset: e.timezone.offset,
        });
      }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
      getOtherTimesheets(payload);
    }
    setSelectedMember("");
  };

  const onDateSelect = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === "Member") {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  const onTimezoneSelect = (timezone) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setSelectedTimezone(timezone);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== "") {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.type === "Member") {
        payload.timezone = timezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const selectLastWeek = () => {
    setStartDate(moment(currentDate).subtract(6, "days"));
    setEndDate(currentDate);
  };
  const selectLastMonth = () => {
    setStartDate(moment(currentDate).subtract(29, "days"));
    setEndDate(currentDate);
  };

  const toggleDetailsPanel = (value) => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };

  const getTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
        user_id: userId,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };

  const handleSetUserData = (date, hours) => {
    if (date && hours) {
      setDetailsUserData({
        id: selectedMember?.id ?? localStorage.getItem("user_id"),
        name:
          selectedMember?.name ??
          (profileData?.id ? getFullName(profileData) : null),
        avatar: selectedMember
          ? selectedMember.avatar ?? null
          : profileData?.thumbnail ?? null,
        color: selectedMember
          ? selectedMember.color ?? null
          : profileData?.color ?? null,
        date,
        totalTime: hours,
      });
    }
  };

  const selectPanelDate = (date, userId) => {
    setSelectedPanelDate(moment(date).format("YYYY-MM-DD"));
    const payload = {
      organization_id: selectedOrganization.id,
      date: date.format("YYYY-MM-DD"),
      user_id: userId,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== "") {
      payload.user_id = selectedMember.id;
    }
    if (selectedOrganization.timezone) {
      payload.timezone = selectedOrganization.timezone;
    }
    getDailyTimesheets(payload);
  };

  const singlePanelDateChange = (direction, userId) => {
    setSelectedPanelDate((prevDate) => {
      const currentDate = moment(prevDate).format("YYYY-MM-DD");
      let newDate = null;
      if (direction === "right") {
        newDate =
          relevantDates.find((date) => date > currentDate) ||
          moment(prevDate).add(1, "days").format("YYYY-MM-DD");
      } else {
        newDate =
          [...relevantDates].reverse().find((date) => date < currentDate) ||
          moment(prevDate).subtract(1, "days").format("YYYY-MM-DD");
      }

      selectPanelDate(moment(newDate), userId);
      return moment(newDate);
    });
  };

  useEffect(() => {
    setActiveDate(selectedPanelDate);
  }, [selectedPanelDate]);

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0" />
        ) : (
          <>
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              selectedProject={selectedProject}
              selectedTask={selectedTask}
              timezone={selectedTimezone && selectedTimezone.timezone}
              fromPage="other"
              expandedAll={expandedAll}
              setExpandedAll={setExpandedAll}
              expandedTimesheets={expandedTimesheets}
              setExpandedTimesheets={setExpandedTimesheets}
              selectedPanelDate={moment(selectedPanelDate)}
              singlePanelDateChange={singlePanelDateChange}
              onPanelDateChange={selectPanelDate}
              disabledNext={disabledNext}
              disabledPrev={disabledPrev}
              setDetailsPanelIsOpen={setDetailsPanelIsOpen}
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Custom Timesheet</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="custom"
        startDate={startDate}
        endDate={endDate}
        onDateSelect={onDateSelect}
        changeDateRange={changeDateRange}
        selectLastWeek={selectLastWeek}
        selectLastMonth={selectLastMonth}
        selectedProject={selectedProject}
        selectProject={selectProject}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        selectedTask={selectedTask}
        selectTask={selectTask}
        selectedOrganization={selectedOrganization}
        selectTimezone={onTimezoneSelect}
        selectedTimezone={selectedTimezone}
      />
      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <TotalTimeContainer>
              <TimeRangeText>{`${moment(startDate).format(
                "MMMM DD"
              )} - ${moment(endDate).format("MMMM DD")}`}</TimeRangeText>
              <TotalTimeDiv>
                <TotalTimeLabel>Total Hour</TotalTimeLabel>{" "}
                <TotalTimeText>
                  {timesheetsData &&
                  timesheetsData.durations &&
                  timesheetsData.durations.length > 0
                    ? getHours(
                        toHHMMSS(
                          timesheetsData.durations
                            .slice(1)
                            .reduce(
                              (prev, cur) => cur + prev,
                              timesheetsData.durations[0]
                            )
                        )
                      )
                    : "0 h 0 m"}
                </TotalTimeText>
              </TotalTimeDiv>
            </TotalTimeContainer>

            <TableContainer>
              <TableHeadContainer custom>
                {timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 &&
                  timesheetsData.timesheets
                    .slice(0, 7)
                    .map((time, index) => (
                      <TableHeadItem key={index}>
                        {moment(time.date).format("ddd")}
                      </TableHeadItem>
                    ))}
              </TableHeadContainer>
              {timesheetsData &&
              timesheetsData.rows &&
              timesheetsData.rows.length > 0
                ? timesheetsData.rows.map((item) => (
                    <TableBodyContainer key={item} custom>
                      {timesheetsData.timesheets
                        .slice(item * 7, (item + 1) * 7)
                        .map((time, index) => {
                          if (!bgColors[time.date])
                            bgColors[time.date] = getRandomColor();
                          return (
                            <TableBodyItem key={index}>
                              {time.hours === "0 h 0 m" ? (
                                <DailyTime
                                  backgroundColor="none"
                                  isActive={
                                    activeDate ===
                                      moment(time.date).format("YYYY-MM-DD") &&
                                    detailsPanelIsOpen
                                  }
                                >
                                  -
                                </DailyTime>
                              ) : (
                                <DailyTimeWithSheet
                                  backgroundColor={bgColors[time.date]}
                                  onClick={() => {
                                    getTimesheetDetails(
                                      time.date,
                                      selectedMember
                                        ? selectedMember.id
                                        : localStorage.getItem("user_id")
                                    );
                                    handleSetUserData(time.date, time.hours);
                                    setSelectedPanelDate(
                                      moment(time.date).format("YYYY-MM-DD")
                                    );
                                  }}
                                  largerZ={detailsPanelIsOpen}
                                  isActive={
                                    activeDate ===
                                      moment(time.date).format("YYYY-MM-DD") &&
                                    detailsPanelIsOpen
                                  }
                                >
                                  {time.hours}
                                </DailyTimeWithSheet>
                              )}
                              <TimesheetDate
                                today={moment().isSame(time.date, "day")}
                              >
                                {moment(time.date).format("MMM DD")}
                              </TimesheetDate>
                            </TableBodyItem>
                          );
                        })}
                    </TableBodyContainer>
                  ))
                : timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 && (
                    <>
                      <TableBodyContainer custom>
                        {timesheetsData.timesheets
                          .slice(0, 7)
                          .map((time, index) => (
                            <TableBodyItem key={index}>
                              <DailyTime>-</DailyTime>
                              <TimesheetDate
                                today={moment().isSame(time.date, "day")}
                              >
                                {moment(time.date).format("MMM DD")}
                              </TimesheetDate>
                            </TableBodyItem>
                          ))}
                      </TableBodyContainer>
                      <TableBodyContainer custom>
                        {timesheetsData.timesheets
                          .slice(7, 14)
                          .map((time, index) => (
                            <TableBodyItem key={index}>
                              <DailyTime>-</DailyTime>
                              <TimesheetDate
                                today={moment().isSame(time.date, "day")}
                              >
                                {moment(time.date).format("MMM DD")}
                              </TimesheetDate>
                            </TableBodyItem>
                          ))}
                      </TableBodyContainer>
                    </>
                  )}
            </TableContainer>
          </>
        )}
      </TimesheetContainer>
    </div>
  );
};

export default CustomRange;
