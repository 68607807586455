import { useState, useEffect } from "react";
import ReportsFilter from "../reportsFilterContainer";
import AppsUrlSection from "./AppsUrlSection";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ExportModal from "../ExportModal";
import moment from "moment";
import {
  PageTitle,
  CommonGrid,
  Container,
  CardTitle,
} from "../../../styledComponents/common";
import {
  ExportButtonIcon,
  ExportButtonWithIcon,
} from "../../../styledComponents/buttons";
import { ActivityTextSection } from "../../../styledComponents/members";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import exportImg from "../../../assets/img/icons/export_icon.svg";

const getDates = (startDate, endDate) => {
  const dates = [];
  let end = endDate;
  while (moment(end).isSameOrAfter(moment(startDate))) {
    dates.push(moment(end).format("YYYY-MM-DD"));
    end = moment(end).subtract(1, "days");
  }
  return dates;
};

const AppsUrlUsage = (props) => {
  const [showGraph, setShowGraph] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [appsUrlState, setAppsUrlState] = useState({});
  const [sortType, setSortType] = useState({
    label: "Date (New to old)",
    value: "NewToOld",
  });
  const [sortedState, setSortedState] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Active Projects)",
  });

  const {
    selectedOrganization,
    appTimeData,
    appReportData,
    urlReportData,
    isLoading,
    noData,
  } = props;

  const changeSortType = (type) => {
    setSortType(type);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const changeProjectInTable = (value) => {
    setSelectedProject(value);
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedProject({ value: null, label: "(All Active Projects)" });
      setSortType({
        label: "Date (New to old)",
        value: "NewToOld",
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (appReportData?.length > 0 || urlReportData?.length > 0) {
      let dateArray = [];
      if (appTimeData && appTimeData.startDate && appTimeData.endDate) {
        dateArray = getDates(appTimeData.startDate, appTimeData.endDate);
      }

      let AppsUrlInfo = {};
      dateArray.forEach((date) => {
        AppsUrlInfo[date] = {};
      });

      for (let date in AppsUrlInfo) {
        if (
          appTimeData &&
          appTimeData.data &&
          Object.keys(appTimeData.data).length > 0
        ) {
          let memberObject = {};
          let hasData = false;
          for (let property in appTimeData.data) {
            if (
              appTimeData.data[property][date] &&
              Object.keys(appTimeData.data[property][date]).length > 0
            ) {
              memberObject[property] = {
                duration: appTimeData.data[property][date].duration,
              };
              if (appReportData && appReportData.length > 0) {
                appReportData.forEach((appItem, index) => {
                  if (
                    appItem.member_id === property &&
                    appItem.working_day === date
                  ) {
                    memberObject[property] = {
                      ...memberObject[property],
                      member_avatar: appItem.member_avatar,
                      member_name: appItem.member_name,
                      member_color: appItem.member_color,
                      app_data: appItem.app_data,
                    };
                    if (!hasData) {
                      hasData = true;
                    }
                  }
                });
              }
              if (urlReportData && urlReportData.length > 0) {
                urlReportData.forEach((urlItem, index) => {
                  if (
                    urlItem.member_id === property &&
                    urlItem.working_day === date
                  ) {
                    memberObject[property] = {
                      ...memberObject[property],
                      url_data: urlItem.url_data,
                    };
                    if (!hasData) {
                      hasData = true;
                    }
                  }
                });
              }
            }
          }
          memberObject.hasData = hasData;
          if (hasData) {
            memberObject = Object.keys(memberObject)
              .sort((a, b) =>
                memberObject[a].member_name?.localeCompare(
                  memberObject[b].member_name
                )
              )
              .reduce((acc, key) => {
                acc[key] = memberObject[key];
                return acc;
              }, {});
          }

          AppsUrlInfo[date] = memberObject;
        }
      }
      setAppsUrlState(AppsUrlInfo);
    } else if (appReportData?.length === 0 && urlReportData?.length === 0) {
      setAppsUrlState({});
    }
  }, [appReportData, appTimeData, urlReportData]);

  useEffect(() => {
    let sortedList = {};
    Object.keys(appsUrlState)
      .slice(0)
      .reverse()
      .map((date) => (sortedList[date] = appsUrlState[date]));
    setSortedState(sortedList);
  }, [appsUrlState]);

  return (
    <div className="content">
      <PageTitle>Apps & URLs Usage Report</PageTitle>
      <ReportsFilter
        title="appUrl"
        sortType={sortType}
        changeSortType={changeSortType}
        changeProjectInTable={changeProjectInTable}
      />
      <Container padding="30px" marginTop="20px">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {((appReportData && appReportData.length > 0) ||
              (urlReportData && urlReportData.length > 0)) &&
              !isLoading && (
                <CommonGrid alignItem="center">
                  <ActivityTextSection
                    gap="5px"
                    style={{ alignContent: `start` }}
                  >
                    <CardTitle>{selectedProject?.label}</CardTitle>
                  </ActivityTextSection>
                  <ExportButtonWithIcon onClick={() => popupToggle()}>
                    <ExportButtonIcon src={exportImg} alt="" />
                    Export
                  </ExportButtonWithIcon>
                </CommonGrid>
              )}
            <ExportModal
              isOpen={popupOpen}
              toggle={popupToggle}
              title="appUrl"
              appsUrlState={
                sortType.value === "NewToOld" ? appsUrlState : sortedState
              }
            />

            {appsUrlState &&
            Object.keys(appsUrlState).length > 0 &&
            sortType.value === "NewToOld"
              ? Object.keys(appsUrlState).map((date, index) => {
                  if (appsUrlState[date] && appsUrlState[date].hasData) {
                    return (
                      <AppsUrlSection
                        report={appsUrlState[date]}
                        date={date}
                        key={index}
                        index={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              : Object.keys(sortedState).map((date, index) => {
                  if (sortedState[date] && sortedState[date].hasData) {
                    return (
                      <AppsUrlSection
                        report={sortedState[date]}
                        date={date}
                        key={index}
                        index={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            {appReportData &&
              appReportData.length === 0 &&
              urlReportData &&
              urlReportData.length === 0 &&
              noData && (
                <NoDataComponent title="Sorry! there is no Apps & URLs Usage Report." />
              )}
          </>
        )}
      </Container>
    </div>
  );
};

export default AppsUrlUsage;
