import { useCallback } from "react";
import moment from "moment";

export const useTimesheetActions = ({
  createDailyTimesheets,
  updateDailyTimesheets,
  deleteDailyTimesheets,
  selectedOrganization,
  getTimesheetList,
  selectedMember,
  setDeleteTimesheetModalVisible,
}) => {
  const handleAddTimesheet = useCallback(
    (payload) => {
      createDailyTimesheets(payload);
    },
    [createDailyTimesheets]
  );

  const handleUpdateTimesheet = useCallback(
    (payload) => {
      updateDailyTimesheets(payload);
    },
    [updateDailyTimesheets]
  );

  const handleDeleteTimesheet = useCallback(
    (id, startTimestamp, endTimestamp, reason) => {
      if (selectedOrganization?.id) {
        const userId = localStorage.getItem("user_id");
        const payload = {
          organization_id: selectedOrganization.id,
          timesheet_id: id,
          start: moment(startTimestamp).utc().unix(),
          end: moment(endTimestamp).utc().unix(),
          comment: reason,
        };

        if (
          selectedOrganization.role &&
          selectedOrganization.role !== "member" &&
          selectedMember &&
          selectedMember.id &&
          userId &&
          userId !== selectedMember.id
        ) {
          payload.user_id = selectedMember.id;
        }

        deleteDailyTimesheets(payload);

        setDeleteTimesheetModalVisible(false);
        setTimeout(() => {
          getTimesheetList();
        }, 800);
      }
    },
    [
      selectedOrganization,
      deleteDailyTimesheets,
      getTimesheetList,
      selectedMember,
    ]
  );

  return {
    handleAddTimesheet,
    handleUpdateTimesheet,
    handleDeleteTimesheet,
  };
};
