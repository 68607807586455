import moment from "moment";
import DateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";
import SingleDatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import { FilterButton } from "../../styledComponents/buttons";
import { FilterContainer } from "../../styledComponents/timesheet";
import {
  FilterLabel,
  InputWithLabelSection,
  CommonFlex,
} from "../../styledComponents/common";

import CommonFilter from "../../components/CommonFilter/commonFilterContainer";

import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";

import LeftArrow_3 from "../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../assets/img/icons/RightArrow_3.svg";
import { IconArrow } from "../../styledComponents/Activity";
import ReactWeekPicker from "components/ReactWeekPicker/ReactWeekPicker";
import { getDateLabel } from "../../utils/helper";

const TimesheetFilter = ({
  page,
  selectedProject,
  selectProject,
  selectedTask,
  selectTask,
  selectedMember,
  selectMember,
  clearMember,
  startDate,
  endDate,
  changeDateRange,
  onDateSelect,
  selectLastWeek,
  selectCurrentWeek,
  selectLastMonth,
  selectedOrganization,
  selectTimezone,
  selectedTimezone,
  singleDateChange,
  selectedDate,
  onDateChange,
  showWeekPickerFooter = true,
  isToday,
  selectToday,
}) => {
  return (
    <FilterContainer
      columns={
        page && page !== "weekly"
          ? page === "custom"
            ? "auto auto"
            : page === "daily"
            ? // ? "2fr 2fr auto"
              "auto auto"
            : page === "biweekly"
            ? "auto auto"
            : page === "monthly"
            ? "1fr auto"
            : "auto auto"
          : "auto"
      }
      justify="space-between"
      width={page === "monthly" ? "78vw" : ""}
    >
      <CommonFlex>
        <CommonFilter
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedTask={selectedTask}
          selectTask={selectTask}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          columns={page && page === "custom" && "180px 180px 57px"}
          addAllProjects={true}
          addAllTasksOption={true}
        />
        {page && page !== "monthly" && (
          <InputWithLabelSection>
            {page && page === "custom" && (
              <>
                <FilterLabel>
                  Date Range{getDateLabel({ startDate, endDate })}
                </FilterLabel>
                <DateRangePicker
                  startDate={startDate ? startDate : null}
                  startDateId="custom-date-range-start-date"
                  endDate={endDate ? endDate : null}
                  endDateId="custom-date-range-start-date"
                  onDatesChange={changeDateRange}
                  dateDisplayFormat="DD/MM/YYYY"
                  onDateSelect={onDateSelect}
                  selectLastWeek={selectLastWeek}
                  selectLastMonth={selectLastMonth}
                />
              </>
            )}
            {page && (page === "weekly" || page === "biweekly") && (
              <>
                <FilterLabel>
                  Date{getDateLabel({ startDate, endDate })}
                </FilterLabel>
                <ReactWeekPicker
                  startDate={startDate ? moment(startDate) : null}
                  startDateId="report-date-range-start-date"
                  endDate={endDate ? moment(endDate) : null}
                  endDateId="report-date-range-end-date"
                  onDatesChange={changeDateRange}
                  dateDisplayFormat="DD/MM/YYYY"
                  onDateSelect={onDateSelect}
                  selectLastWeek={selectLastWeek}
                  selectCurrentWeek={selectCurrentWeek}
                  showFooter={showWeekPickerFooter}
                />
              </>
            )}{" "}
            {page && page === "daily" && (
              <InputWithLabelSection>
                <FilterLabel>
                  Date{" "}
                  {getDateLabel({
                    startDate: selectedDate,
                    endDate: selectedDate,
                    onlyToday: true,
                  })}
                </FilterLabel>
                <CommonFlex gap="2px">
                  <SingleDatePicker
                    dateDisplayFormat="DD/MM/YYYY"
                    placeholder="Date"
                    date={selectedDate}
                    onDateChange={onDateChange}
                    allowPreviousDates
                    disableFutureDates
                  />
                  <CommonFlex gap="0px">
                    <IconArrow
                      onClick={() => {
                        singleDateChange("left");
                      }}
                      padding="5px"
                      size="40px"
                      src={LeftArrow_3}
                    />
                    <IconArrow
                      margin="0 0 0 2px"
                      onClick={() => {
                        if (!isToday) {
                          singleDateChange("right");
                        }
                      }}
                      padding="5px"
                      size="40px"
                      src={RightArrow_3}
                      disabled={isToday}
                    />
                  </CommonFlex>
                  <FilterButton
                    type="gray"
                    padding="10px"
                    onClick={() => selectToday()}
                  >
                    Today
                  </FilterButton>
                </CommonFlex>
              </InputWithLabelSection>
            )}
          </InputWithLabelSection>
        )}
      </CommonFlex>
      <div>
        {page && page !== "weekly" && selectedTimezone && (
          <InputWithLabelSection>
            <FilterLabel>Timezone</FilterLabel>
            <TimezoneDropdown
              selectedOrganization={selectedOrganization}
              selectedMember={selectedMember}
              selectTimezone={selectTimezone}
              selectedTimezone={selectedTimezone}
              dropdownWidth="140px"
            />
          </InputWithLabelSection>
        )}
      </div>
    </FilterContainer>
  );
};

export default TimesheetFilter;
