//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_DAILY_TIMESHEETS_LIST,
  GET_DAILY_TIMESHEETS_LIST_SUCCESS,
  GET_DAILY_TIMESHEETS_LIST_FAILURE,
  GET_OTHER_TIMESHEETS_LIST,
  GET_OTHER_TIMESHEETS_LIST_SUCCESS,
  GET_OTHER_TIMESHEETS_LIST_FAILURE,
  GET_WEEKLY_TIMESHEET_DETAILS,
  GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS,
  GET_WEEKLY_TIMESHEET_DETAILS_FAILURE,
  CREATE_DAILY_TIMESHEETS_LIST,
  CREATE_DAILY_TIMESHEETS_LIST_SUCCESS,
  CREATE_DAILY_TIMESHEETS_LIST_FAILURE,
  UPDATE_DAILY_TIMESHEETS_LIST,
  UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS,
  UPDATE_DAILY_TIMESHEETS_LIST_FAILURE,
  DELETE_DAILY_TIMESHEETS_LIST,
  DELETE_DAILY_TIMESHEETS_LIST_SUCCESS,
  DELETE_DAILY_TIMESHEETS_LIST_FAILURE,
  EXPORT_WEEKLY_TIMESHEET,
  EXPORT_WEEKLY_TIMESHEET_SUCCESS,
  EXPORT_WEEKLY_TIMESHEET_FAILURE,
  EXPORT_MONTHLY_TIMESHEET_SUCCESS,
  EXPORT_MONTHLY_TIMESHEET_FAILURE,
  EXPORT_MONTHLY_TIMESHEET,
} from "../../modules/constants";

//Handle daily timesheet request
export function* dailyTimesheetRequest({ payload }) {
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/daily-timesheet/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response, timezone },
    });
  } catch (err) {
    yield put({
      type: GET_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

//Handle other timesheets request except daily
export function* otherTimesheetRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    project_id,
    task_id,
    user_id,
    timezone,
  } = payload;
  try {
    let url = `${organization_id}/timesheets/timesheet-range/?start_date=${start_date}&end_date=${end_date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    let method = "GET";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: GET_OTHER_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OTHER_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}
export function* weeklyTimesheetDetailsRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    project_id,
    task_id,
    user_id,
  } = payload;
  try {
    let url = `${organization_id}/timesheets/weekly-timesheet-details/?start_date=${start_date}&end_date=${end_date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }`;
    let method = "GET";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_WEEKLY_TIMESHEET_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* exportWeeklyTimeSheetRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    project_id,
    task_id,
    user_id,
    time_format,
  } = payload;
  try {
    let url = `${organization_id}/timesheets/export-weekly-timesheet-details/?start_date=${start_date}&end_date=${end_date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${time_format ? `&time_format=${time_format}` : ``}`;
    let method = "GET";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: EXPORT_WEEKLY_TIMESHEET_SUCCESS,
      payload: { data: response, start_date, end_date },
    });
  } catch (err) {
    yield put({
      type: EXPORT_WEEKLY_TIMESHEET_FAILURE,
      payload: err,
    });
  }
}
export function* exportMonthlyTimeSheetRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    project_id,
    task_id,
    user_id,
    timezone,
    time_format,
  } = payload;
  try {
    let url = `${organization_id}/timesheets/export-monthly-timesheet-details/?start_date=${start_date}&end_date=${end_date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : `${timezone ? `&tz=${timezone}` : ``}`
    }${time_format ? `&time_format=${time_format}` : ``}`;
    let method = "GET";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: EXPORT_MONTHLY_TIMESHEET_SUCCESS,
      payload: { data: response, start_date, end_date },
    });
  } catch (err) {
    yield put({
      type: EXPORT_MONTHLY_TIMESHEET_FAILURE,
      payload: err,
    });
  }
}
export function* addDailyTimesheetRequest({ payload }) {
  const { organization_id, user, date, ...details } = payload;
  try {
    let url = `${organization_id}/timesheets/timesheets-manual/`;
    let method = "POST";
    details.user = user.id
    const response = yield defaultApi(url, method, details);
    response.data.user = user
    response.data.date = date
    
    yield put({
      type: CREATE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response, notify: "POST"},
    });
  } catch (err) {
    yield put({
      type: CREATE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* updateDailyTimesheetRequest({ payload }) {
  try {
    const { organization_id, id, ...data } = payload;
    let url = `${organization_id}/timesheets/${id}/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield put({
      type: UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* deleteDailyTimesheetRequest({ payload }) {
  try {
    const { organization_id, timesheet_id, fromPage, ...data } = payload;
    let url = `${organization_id}/timesheets/timesheets-manual/${timesheet_id}/delete/`;
    const response = yield defaultApi(url, "POST", data);
    let duration = null;
    if (data.start && data.end) {
      duration = data.end - data.start;
    }
    yield put({
      type: DELETE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: {
        data: response,
        duration,
        fromPage,
        userId: data.user_id,
        date: data.date,
      },
    });
  } catch (err) {
    yield put({
      type: DELETE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_DAILY_TIMESHEETS_LIST, dailyTimesheetRequest),
    takeLatest(GET_OTHER_TIMESHEETS_LIST, otherTimesheetRequest),
    takeLatest(GET_WEEKLY_TIMESHEET_DETAILS, weeklyTimesheetDetailsRequest),
    takeLatest(CREATE_DAILY_TIMESHEETS_LIST, addDailyTimesheetRequest),
    takeLatest(UPDATE_DAILY_TIMESHEETS_LIST, updateDailyTimesheetRequest),
    takeLatest(DELETE_DAILY_TIMESHEETS_LIST, deleteDailyTimesheetRequest),
    takeLatest(EXPORT_WEEKLY_TIMESHEET, exportWeeklyTimeSheetRequest),
    takeLatest(EXPORT_MONTHLY_TIMESHEET, exportMonthlyTimeSheetRequest),
  ]);
}
