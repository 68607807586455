import { useState, useEffect } from "react";
import {
  PageTitle,
  CommonFlex,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  Container,
  CardTitle,
  CommonText,
  CommonGrid,
} from "../../../styledComponents/common";
import {
  ButtonIcon,
  TabButtonContainer,
  TabButton,
  PrimaryButton,
  ColoredButtonWithIcon,
  RefreshButton,
} from "../../../styledComponents/buttons";
import { DotContainer, DotCircle, InfoDiv } from "./currentlyTrackingStyles";
import { TabSearchGrid } from "../../../styledComponents/ProjectsNew";

import CurrentlyTrackingTable from "./CurrentlyTrackingTable";
import LastFiveMinTable from "./LastFiveMinTable";

import refreshIcon from "../../../assets/img/icons/refresh_white.svg";
import { getFullName } from "../../../utils/helper";
import InputWithClearButton from "../../../components/Search/InputWithClearButton";

export const PulsatingDot = ({ active, size }) => {
  return (
    <DotContainer>
      <DotCircle size={size} active={active} />
    </DotContainer>
  );
};

const CurrentlyTracking = (props) => {
  const [membersList, setMembersList] = useState([]);
  const [lastFiveMinMembersList, setLastFiveMinMembersList] = useState([]);
  const [tabSelect, setTabSelect] = useState("fiveMin");
  const [searchTerm, setSearchTerm] = useState("");
  const {
    getLiveMembersList,
    getLastFiveMinutesActiveList,
    selectedOrganization,
    liveMembersList,
    lastFiveMinActiveList,
    memberListLoading,
    takeInstantScreenshot,
    buttonLoading,
    history,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = { organization_id: selectedOrganization.id };
      // getLiveMembersList(payload);
      getLastFiveMinutesActiveList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (liveMembersList && liveMembersList.length > 0) {
      setMembersList(liveMembersList);
    } else if (liveMembersList && liveMembersList.length === 0) {
      setMembersList([]);
    }
  }, [liveMembersList]);

  useEffect(() => {
    if (lastFiveMinActiveList && lastFiveMinActiveList.length > 0) {
      setLastFiveMinMembersList(lastFiveMinActiveList);
    } else if (lastFiveMinActiveList && lastFiveMinActiveList.length === 0) {
      setLastFiveMinMembersList([]);
    }
  }, [lastFiveMinActiveList]);

  useEffect(() => {
    if (searchTerm && searchTerm !== "") {
      let originalList = [];
      if (
        tabSelect === "live" &&
        liveMembersList &&
        liveMembersList.length > 0
      ) {
        originalList = JSON.parse(JSON.stringify(liveMembersList));
      } else if (
        tabSelect === "fiveMin" &&
        lastFiveMinActiveList &&
        lastFiveMinActiveList.length > 0
      ) {
        originalList = JSON.parse(JSON.stringify(lastFiveMinActiveList));
      }

      const filteredList = [];
      originalList.forEach((e) => {
        const searchField = getFullName(e.user);
        if (
          searchTerm &&
          searchField.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) ===
            -1
        ) {
          return;
        }
        if (selectedOrganization) {
          filteredList.push(e);
        }
      });
      if (tabSelect === "live") {
        setMembersList(filteredList);
      } else if (tabSelect === "fiveMin") {
        setLastFiveMinMembersList(filteredList);
      }
    } else if (searchTerm === "") {
      if (tabSelect === "live") {
        setMembersList(liveMembersList);
      } else if (tabSelect === "fiveMin") {
        setLastFiveMinMembersList(lastFiveMinActiveList);
      }
    }
  }, [searchTerm]);

  const handleGetLiveMembers = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = { organization_id: selectedOrganization.id };
      if (tabSelect === "live") {
        getLiveMembersList(payload);
      } else if (tabSelect === "fiveMin") {
        getLastFiveMinutesActiveList(payload);
      }
    }
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const clearSearchTerm = () => {
    setSearchTerm("");
  };

  const onTabSwitch = (value) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      if (value === "live") {
        getLiveMembersList(payload);
      } else if (value === "fiveMin") {
        getLastFiveMinutesActiveList(payload);
      }
    }
    setSearchTerm("");
    setTabSelect(value);
  };

  return (
    <div className="content">
      <CommonFlex gap="5px" margin="0">
        <PageTitle margin="0">Who Is Working</PageTitle>
        {/* <PulsatingDot /> */}
      </CommonFlex>
      {/* <InfoDiv>
        <CommonText name margin="0" fontSize="13px">
          Apploye is showing live feed for the members who are using the latest
          version of our desktop app (version: 3.1.6 or later).
        </CommonText>
        <CommonText margin="15px 0 0 0" fontSize="12px">
          Let all your members use the latest version of your desktop app to see
          their live feed.
        </CommonText>
      </InfoDiv> */}
      <HeaderContainer justifyContent="space-between" margin="20px 0">
        <TabSearchGrid columns="auto 280px">
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch("fiveMin")}
                selected={tabSelect}
                index={"fiveMin"}
              >
                Last 5 Min
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch("live")}
                selected={tabSelect}
                index={"live"}
              >
                Live
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>Search Member</FilterLabel>
            <InputWithClearButton
              searchTerm={searchTerm}
              onChange={onSearchTermChange}
              placeHolder={"Search by member name..."}
              clearFunction={clearSearchTerm}
            />
          </InputWithLabelSection>
        </TabSearchGrid>

        <CommonGrid>
          {/* <RefreshButton
            onClick={() => handleGetLiveMembers()}
            isLoading={buttonLoading}
          ></RefreshButton> */}
          <RefreshButton
            onClick={() => handleGetLiveMembers()}
            isLoading={buttonLoading}
            padding="0 20px"
          >
            <ButtonIcon src={refreshIcon} alt="" size="22px" />
            Refresh
          </RefreshButton>
          {selectedOrganization &&
            selectedOrganization.configuration &&
            selectedOrganization.configuration.screenshot_track && (
              <PrimaryButton
                onClick={() => history.push("/user/instant-screenshots")}
              >
                Instant Screenshots
              </PrimaryButton>
            )}
        </CommonGrid>
      </HeaderContainer>
      {tabSelect && tabSelect === "fiveMin" ? (
        <LastFiveMinTable
          liveMembersList={lastFiveMinMembersList}
          isLoading={memberListLoading}
          selectedOrganization={selectedOrganization}
          searchTerm={searchTerm}
        />
      ) : (
        <CurrentlyTrackingTable
          liveMembersList={membersList}
          isLoading={memberListLoading}
          selectedOrganization={selectedOrganization}
          searchTerm={searchTerm}
          history={history}
          takeInstantScreenshot={takeInstantScreenshot}
        />
      )}
    </div>
  );
};

export default CurrentlyTracking;
