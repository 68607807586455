import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";
import ProjectDropdown from "../../../../components/CommonFilter/ProjectDropdown";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  CardTitle,
  CommonFlex,
  FilterLabel,
  FormAlert,
  InputWithLabelSection,
  PageTitle,
  RedStar,
  ContentHeader,
} from "../../../../styledComponents/common";
import {
  Container,
  InputField,
} from "../../../../styledComponents/createProject";
import { CreateCustomReportDropdowns } from "../customReportStyles";
import AddMultiMemberDropdown from "../../../../components/CustomDropdown/AddMultiMemberDropdown";
import ReactDateRangePicker from "../../../../components/ReactDateRangePicker/ReactDateRangePicker";
import CustomReportFeatureButton from "./customReportFeatureButtons";
import {
  PrimaryButton,
  TabButton,
  TabButtonContainer,
} from "../../../../styledComponents/buttons";
import FilterDropDownStyle from "../../../../components/DropdownStyle/FilterDropDownStyle";
import TaskDropdown from "../../../../components/CommonFilter/TaskDropdown";
import { getDateLabel, getFullName } from "../../../../utils/helper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../../components/NoDataComponent/NoDataComponent";

const CreateCustomReport = ({
  history,
  organizationMembersList,
  getOrganizationMembersList,
  organization,
  isLoading,
  createReportFailed,
  createdReport,
  createCustomReport,
  getProjectAssigneeList,
  projectAssigneeList,
  getTaskAssigneeList,
  taskAssigneeList,
  getCustomReportDetails,
  customReportDetails,
  updateCustomReport,
}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const isEditMode = history.location.pathname.includes("edit-custom-report");

  const [taskAssignees, setTaskAssignees] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );
  const [saveErrors, setSaveErrors] = useState({});
  const [reportName, setReportName] = useState(
    `New Report ${Math.round(Math.random(555) * 1000)}`
  );
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Active Projects)",
  });
  const [selectedTask, setSelectedTask] = useState({
    value: "",
    label: "(All Tasks)",
  });
  const [selectedMembers, setSelectedMembers] = useState([]);
  const initialFeatureList = {
    project: {
      title: "Project",
      selected: true,
    },
    task: {
      title: "Task",
      selected: false,
    },
    total_time: {
      title: "Total Time",
      selected: true,
    },
    manual_time: {
      title: "Manual Time",
      selected: false,
    },
    notes: {
      title: "Notes",
      selected: false,
    },
    // task: {
    //   title: "Task",
    //   selected: true,
    // },
    // app_usage: {
    //   title: "App Usage",
    //   selected: false,
    // },
  };

  const [featureList, setFeatureList] = useState({ ...initialFeatureList });
  const [reportDetails, setReportDetails] = useState(null);

  const [selectedTimeBreakdown, setSelectedTimeBreakdown] =
    useState("all_task");

  useEffect(() => {
    if (organization?.configuration) {
      const { mouse_track, keystroke_track } = organization.configuration;
      if (mouse_track === true && keystroke_track === true) {
        setFeatureList({
          ...featureList,
          idle_time: {
            title: "Idle Time",
            selected: false,
          },
          average_activity: {
            title: "Average Activity",
            selected: false,
          },
          active_time: {
            title: "Active Time",
            selected: false,
          },
          neutral_time: {
            title: "Neutral Time",
            selected: false,
          },
        });
      } else {
        setFeatureList({ ...initialFeatureList });
      }
    }
  }, [organization]);

  useEffect(() => {
    if (isEditMode && id && customReportDetails?.id !== id) {
      getCustomReportDetails({
        organization_id: organization.id,
        report_id: id,
      });
    }
  }, [isEditMode, id, getCustomReportDetails, organization]);

  useEffect(() => {
    if (customReportDetails) {
      setReportName(customReportDetails.name || "");
      setStartDate(
        customReportDetails.start_date ||
          moment().startOf("isoWeek").format("YYYY-MM-DD")
      );
      setEndDate(
        customReportDetails.end_date ||
          moment().endOf("isoWeek").format("YYYY-MM-DD")
      );

      if (customReportDetails.features?.includes("task_wise")) {
        setSelectedTimeBreakdown("task_wise");
      }
      setFeatureList((prevFeatureList) => {
        const updatedFeatureList = {
          ...prevFeatureList,
        };
        updatedFeatureList["project"].selected = false;
        updatedFeatureList["total_time"].selected = false;
        customReportDetails.features.forEach((feature) => {
          if (updatedFeatureList[feature]) {
            updatedFeatureList[feature].selected = true;
          }
        });
        return updatedFeatureList;
      });
      setReportDetails(customReportDetails);
    }
  }, [customReportDetails]);

  useEffect(() => {
    if (
      reportDetails &&
      reportDetails?.user_ids?.length >= 0 &&
      organizationMembersList?.length > 0 &&
      !reportDetails?.project_ids &&
      !reportDetails?.task_ids
    ) {
      const preSelectedMembers = organizationMembersList.filter((member) =>
        reportDetails.user_ids.includes(member.id)
      );
      setSelectedMembers(preSelectedMembers);
      setReportDetails({
        ...reportDetails,
        user_ids: null,
      });
    }
  }, [
    reportDetails?.user_ids,
    organizationMembersList,
    reportDetails?.project_ids,
    reportDetails?.task_ids,
  ]);

  useEffect(() => {
    if (
      reportDetails?.project_ids?.length >= 0 &&
      selectedProject?.value == reportDetails?.project_ids[0]
    ) {
      if (!reportDetails.project_ids[0]) {
        setReportDetails({
          ...reportDetails,
          task_ids: null,
          project_ids: null,
        });
      } else {
        setReportDetails({
          ...reportDetails,
          project_ids: null,
        });
      }
    }
  }, [reportDetails?.project_ids, selectedProject]);

  const updateSelection = (d) => {
    setFeatureList((prevFeatureList) => ({
      ...prevFeatureList,
      [d.key]: d,
    }));
  };

  const updateTimeBreakdownSelection = (val) => {
    setSelectedTimeBreakdown(val);
  };

  const updateSelectedMembers = (members) => {
    setSelectedMembers(members);
  };

  const onProjectSelect = (project) => {
    setSelectedProject(project);
    setSelectedTask(null);
  };

  const clearSelectedMembers = () => {
    setSelectedMembers([]);
  };

  const onTaskSelect = (task) => {
    setSelectedTask(task);
  };

  const changeDateRange = (e) => {
    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
  };

  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let errors = { ...saveErrors };
    delete errors["dateRange"];
    setSaveErrors(errors);
  }, [startDate, endDate]);

  useEffect(() => {
    let errors = { ...saveErrors };
    delete errors["reportName"];
    setSaveErrors(errors);
  }, [reportName]);

  useEffect(() => {
    if (organization?.id) {
      getOrganizationMembersList({
        organization_id: organization.id,
      });
    }
  }, [organization]);

  const checkErrors = () => {
    let saveErrors = {};
    let isFormValid = true;
    if (moment(endDate).diff(startDate, "months") >= 3) {
      saveErrors["dateRange"] = "Date range should not be more than 3 months";
      isFormValid = false;
    }
    if (!(reportName?.length > 2)) {
      if (reportName?.length === 0)
        saveErrors["reportName"] = "Report name should not be empty";
      else
        saveErrors["reportName"] =
          "Report name should be more than 2 characters";
      isFormValid = false;
    }
    if (reportName?.length > 255) {
      saveErrors["reportName"] =
        "Ensure this field has no more than 255 characters.";
      isFormValid = false;
    }
    if (!isFormValid) setSaveErrors(saveErrors);
    return isFormValid;
  };

  const handleCreateReport = () => {
    if (checkErrors()) {
      const selectedFeatures = Object.keys(featureList).filter(
        (key) => featureList[key].selected
      );
      if (
        reportName?.length > 2 &&
        organization.id &&
        // selectedProject?.value &&
        startDate &&
        endDate &&
        selectedMembers &&
        selectedFeatures?.length > 1
      ) {
        const newFeatures = [...selectedFeatures];
        if (selectedTimeBreakdown === "task_wise") {
          newFeatures.push("task_wise");
        }
        const payload = {
          organization_id: organization.id,
          project_ids: selectedProject?.value ? [selectedProject?.value] : [],
          name: reportName,
          start_date: startDate,
          end_date: endDate,
          task_ids: [],
          user_ids: selectedMembers?.map(
            (selectedMembers) => selectedMembers.id
          ),
          features: newFeatures,
        };
        if (selectedTask?.value) {
          payload.task_ids = [selectedTask.value];
        }
        if (isEditMode) {
          updateCustomReport({ ...payload, report_id: id });
        } else createCustomReport(payload);
      }
    }
  };

  useEffect(() => {
    if (isLoading === false && createReportFailed === false && createdReport) {
      history.push(`/user/reports/custom-report/${createdReport.id}`);
      setReportName(null);
      setSelectedProject(null);
      setSelectedMembers([]);
      setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
      setFeatureList({ ...initialFeatureList });
    }
  }, [isLoading, createReportFailed, createdReport]);

  useEffect(() => {
    if (reportName) {
      document.querySelector("#report-name")?.focus();
    }
  }, [customReportDetails]);

  useEffect(() => {
    if (selectedProject?.value && organization) {
      getProjectAssigneeList({
        organization_id: organization.id,
        project_id: selectedProject?.value,
      });
    }
    if (isEditMode) {
      if (reportDetails) {
        if (!reportDetails.user_ids) {
          clearSelectedMembers();
        } else if (
          reportDetails.project_ids &&
          selectedProject?.value === reportDetails.project_ids[0]
        ) {
          setReportDetails({
            ...reportDetails,
            project_ids: null,
          });
        }
      }
    } else {
      clearSelectedMembers();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedTask?.value && organization) {
      getTaskAssigneeList({
        organization_id: organization.id,
        project_id: selectedProject.value,
        task_id: selectedTask?.value,
      });
    }

    if (isEditMode) {
      if (reportDetails) {
        if (
          !reportDetails?.project_ids &&
          !reportDetails.user_ids &&
          !reportDetails.task_ids
        ) {
          clearSelectedMembers();
        } else if (
          !reportDetails?.project_ids &&
          reportDetails.task_ids &&
          selectedTask?.value === reportDetails.task_ids[0]
        ) {
          setReportDetails({
            ...reportDetails,
            task_ids: null,
          });
        }
      }
    } else {
      clearSelectedMembers();
    }
  }, [selectedTask, reportDetails?.project_ids]);

  useEffect(() => {
    if (organization?.id && reportDetails) {
      history.push(`/user/reports/custom-report`);
    }
  }, [organization?.id]);

  useEffect(() => {
    if (taskAssigneeList && taskAssigneeList.length > 0) {
      let assigneeList = [];
      taskAssigneeList.forEach((assignee) => {
        assigneeList.push({
          id: assignee.id,
          name: getFullName(assignee),
          avatar: assignee.avatar || null,
          timezone: assignee.timezone || null,
          color: assignee.color || null,
        });
      });
      setTaskAssignees(assigneeList);
    } else if (taskAssigneeList && taskAssigneeList.length === 0) {
      setTaskAssignees([]);
    }
  }, [taskAssigneeList]);

  return (
    <div className="content">
      <PageTitle>
        {isEditMode ? "Edit Custom Report" : "Create Custom Report"}
      </PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/reports/custom-report")}
          subTitle="Back to Custom Reports"
        />
      </ContentHeader>
      <Container
        style={{
          padding: `30px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          position: "relative",
        }}
      >
        {isEditMode && !customReportDetails ? (
          <>
            {!isLoading ? (
              <NoDataComponent title="Report not found" />
            ) : (
              <ComponentCircleLoader />
            )}
          </>
        ) : (
          <>
            {isEditMode &&
              reportDetails &&
              (reportDetails.project_ids ||
                reportDetails.task_ids ||
                reportDetails.user_ids) && (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "100",
                    background: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <ComponentCircleLoader />
                </div>
              )}
            <CardTitle>
              <CommonFlex direction="column" alignItems="start">
                <InputField
                  height="32px"
                  type="text"
                  placeholder="Write Report name"
                  value={reportName}
                  id="report-name"
                  onChange={(e) => setReportName(e.target.value)}
                  style={{
                    minWidth: "175px",
                    width: "fit-content",
                  }}
                />
                {saveErrors &&
                  saveErrors["reportName"] &&
                  saveErrors["reportName"] !== undefined && (
                    <FormAlert margin="">{saveErrors["reportName"]}</FormAlert>
                  )}
              </CommonFlex>
            </CardTitle>
            <CreateCustomReportDropdowns>
              <InputWithLabelSection>
                <FilterLabel>Projects</FilterLabel>
                <ProjectDropdown
                  selectedProject={selectedProject}
                  selectProject={onProjectSelect}
                  selectedOrganization={organization}
                  addAllProjects
                  clearable
                  preselectedProjectId={
                    customReportDetails?.project_ids?.length > 0
                      ? customReportDetails?.project_ids[0]
                      : null
                  }
                />
              </InputWithLabelSection>
              <InputWithLabelSection
                style={{
                  minWidth: "220px",
                  maxWidth: "220px",
                }}
              >
                <FilterLabel>Tasks</FilterLabel>
                <TaskDropdown
                  selectedOrganization={organization}
                  selectedProject={selectedProject}
                  selectedTask={
                    selectedTask?.value
                      ? selectedTask
                      : { value: "", label: "(All Tasks)" }
                  }
                  selectTask={onTaskSelect}
                  style={FilterDropDownStyle({
                    height: "40px",
                    width: "180px",
                  })}
                  addAllTasksOption={true}
                  preselectedTaskId={
                    customReportDetails?.task_ids?.length > 0
                      ? customReportDetails?.task_ids[0]
                      : null
                  }
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <FilterLabel>
                  Assignee <RedStar>*</RedStar>
                </FilterLabel>
                <AddMultiMemberDropdown
                  selectedMembers={selectedMembers}
                  updateSelectedMembers={updateSelectedMembers}
                  membersList={
                    selectedTask?.value
                      ? taskAssignees
                      : selectedProject?.value
                      ? projectAssigneeList?.map((assignee) => ({
                          ...assignee,
                          id: assignee.user_id,
                          name: getFullName(assignee.user),
                          avatar: assignee.user.avatar ?? null,
                          color: assignee.user.color ?? null,
                        }))
                      : organizationMembersList
                  }
                  clearSelectedMembers={clearSelectedMembers}
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <FilterLabel>
                  Date Range{getDateLabel({ startDate, endDate })}
                </FilterLabel>
                <ReactDateRangePicker
                  startDate={startDate ? moment(startDate) : null}
                  startDateId="report-date-range-start-date"
                  endDate={endDate ? moment(endDate) : null}
                  endDateId="report-date-range-end-date"
                  onDatesChange={changeDateRange}
                  dateDisplayFormat="DD/MM/YYYY"
                  onDateSelect={() => {}}
                  selectLastWeek={selectLastWeek}
                  selectLastMonth={selectLastMonth}
                />
                {saveErrors &&
                  saveErrors["dateRange"] &&
                  saveErrors["dateRange"] !== undefined && (
                    <FormAlert margin="">{saveErrors["dateRange"]}</FormAlert>
                  )}
              </InputWithLabelSection>
            </CreateCustomReportDropdowns>
            <InputWithLabelSection>
              <FilterLabel>Time Breakdown</FilterLabel>
              <CommonFlex
                wrap="wrap"
                style={{
                  gap: "20px",
                }}
              >
                <InputWithLabelSection>
                  <TabButtonContainer
                    columns="auto auto"
                    bgColor="rgba(0, 0, 0, 0.05)"
                  >
                    <TabButton
                      onClick={() => updateTimeBreakdownSelection("all_task")}
                      selected={selectedTimeBreakdown}
                      index={"all_task"}
                      activeBgColor="#FFFFFF"
                    >
                      All Task
                    </TabButton>
                    <TabButton
                      onClick={() => updateTimeBreakdownSelection("task_wise")}
                      selected={selectedTimeBreakdown}
                      activeBgColor="#FFFFFF"
                      index={"task_wise"}
                    >
                      Task Wise
                    </TabButton>
                  </TabButtonContainer>
                </InputWithLabelSection>
              </CommonFlex>
            </InputWithLabelSection>
            <InputWithLabelSection>
              <FilterLabel>Choose Feature</FilterLabel>
              <CommonFlex
                wrap="wrap"
                style={{
                  gap: "20px",
                }}
              >
                {Object.keys(featureList).map((key) => {
                  return (
                    <CustomReportFeatureButton
                      updateSelection={updateSelection}
                      data={{
                        key,
                        ...featureList[key],
                      }}
                    />
                  );
                })}
              </CommonFlex>
            </InputWithLabelSection>
            <PrimaryButton
              fontSize="14px"
              disabled={
                !(
                  reportName?.length > 2 &&
                  selectedMembers?.length > 0 &&
                  Object.keys(featureList).filter(
                    (key) => featureList[key].selected
                  ).length > 0
                )
              }
              style={{ marginTop: `auto`, width: "max-content" }}
              onClick={handleCreateReport}
            >
              {isEditMode ? "Update Report" : "Create Report"}
            </PrimaryButton>
          </>
        )}
      </Container>
    </div>
  );
};

export default CreateCustomReport;
