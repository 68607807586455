import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAILURE,
  GET_INVITED_MEMBERS,
  GET_INVITED_MEMBERS_SUCCESS,
  GET_INVITED_MEMBERS_FAILURE,
  INVITE_MEMBER,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_SUCCESS,
  DELETE_MEMBER_INVITATION_FAILURE,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING_SUCCESS,
  GET_MEMBER_WEEKLY_EARNING_FAILURE,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_MONTHLY_EARNING_SUCCESS,
  GET_MEMBER_MONTHLY_EARNING_FAILURE,
  CLEAR_MEMBER_DETAILS_DATA,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  REMOVE_MEMBER,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  DELETE_PROJECT_ASSIGNEE_SUCCESS,
  REMOVE_TEAM_MEMBER_SUCCESS,
  CLEAR_MEMBER_INVITED,
  LOGIN_PROFILE_CREATED_RESET,

  // payment configuration
  SET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_CONFIGURATION_SUCCESS,
  SET_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,

  // settings
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

const noInternetMsg =
  "No Internet Connection! Please connect to the internet and try again";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const membersListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    pageSize: data.data.page_size,
    members: data.data.results,
  };
};
export const membersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    pageSize: null,
    members: [],
  };
};

export const invitedMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
    reloadInvitedMembers: null,
  };
};
export const invitedMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    pageSize: data.data.page_size,
    invitedMembersList: data.data.results,
  };
};
export const invitedMembersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    pageSize: null,
    invitedMembersList: [],
  };
};

export const inviteMemberRequestHandler = (state, action) => {
  return {
    ...state,
    inviteMemberLoading: true,
    memberInvited: false,
  };
};
export const inviteMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, resend },
  } = action;
  if (resend) {
    createNotification("success", "Invitation sent.", 3000);
  }
  return {
    ...state,
    inviteMemberLoading: false,
    memberInvited: true,
  };
};
export const inviteMemberRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg = "Could not invite member. Please try again!";
    switch (response.status) {
      case 400:
        if (response.data.error) {
          errorMsg = response.data.error[0];
        }
        break;
      default:
      // do nothing
    }
    createNotification("error", errorMsg, 4000);
  }
  return {
    ...state,
    inviteMemberLoading: false,
    memberInvited: false,
  };
};

export const deleteMemberInvitationRequestHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: true,
    reloadInvitedMembers: null,
  };
};
export const deleteMemberInvitationRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, currentPageNumber, invitation_id },
  } = action;
  const lastPage = Math.ceil(state.totalMembersCount / state.pageSize);
  createNotification("success", "Invitation deleted.", 2000);

  return {
    ...state,
    reloadInvitedMembers:
      currentPageNumber === lastPage ? state.reloadInvitedMembers : true,
    totalMembersCount: data.data.count - 1,
    pageSize: data.data.page_size,
    deleteInvitationLoading: false,
    invitedMembersList: state.invitedMembersList.filter(
      (invitation) => invitation.id !== invitation_id
    ),
  };
};
export const deleteMemberInvitationRequestFailureHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: false,
  };
};

export const membersDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    memberDetails: data.data,
  };
};
export const membersDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    membersDetails: null,
  };
};

export const memberWeeklyEarningRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const memberWeeklyEarningRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    weeklyEarning: data.data,
  };
};
export const memberWeeklyEarningRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    weeklyEarning: null,
  };
};

export const memberMonthlyEarningRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const memberMonthlyEarningRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    monthlyEarning: data.data,
  };
};
export const memberMonthlyEarningRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    monthlyEarning: null,
  };
};

export const updateMemberRequestHandler = (state, action) => {
  return {
    ...state,
    updateMemberLoading: true,
  };
};
export const updateMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  const details = state.memberDetails;

  if (details?.weekly_tracking_limit !== data.data.weekly_tracking_limit) {
    createNotification("success", "Weekly limit updated.", 4000);
  }

  if (details?.is_admin !== data.data.is_admin) {
    createNotification("success", "Role updated.", 4000);
  }

  if (data.status === 200 && data.data) {
    return {
      ...state,
      updateMemberLoading: false,
      memberDetails: {
        ...details,
        ...data.data,
      },
    };
  }
  return {
    ...state,
    updateMemberLoading: false,
  };
};
export const updateMemberRequestFailureHandler = (state, action) => {
  // const errorMessage = action.payload.error.detail || "Could not update member. Please try again!";
  createNotification(
    "error",
    "Could not update member. Please try again!",
    3000
  );
  return {
    ...state,
    updateMemberLoading: false,
  };
};

export const deleteMemberRequestHandler = (state, action) => {
  return {
    ...state,
    deleteMemberLoading: true,
    reloadMembers: null,
  };
};
export const deleteMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, user_id, currentPageNumber },
  } = action;
  createNotification("success", "Member deleted successfully.", 3000);
  const lastPage = Math.ceil(state.totalMembersCount / state.pageSize);

  return {
    ...state,
    deleteMemberLoading: false,
    reloadMembers: currentPageNumber === lastPage ? state.reloadMembers : true,
    totalMembersCount: data.data.count - 1,
    members: state.members.filter((member) => member.user.id !== user_id),
  };
};
export const deleteMemberRequestFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not delete member. Please try again!",
    3000
  );
  return {
    ...state,
    deleteMemberLoading: false,
  };
};

export const clearMembersDetailsDataRequest = (state, action) => {
  return {
    ...state,
    memberDetails: null,
    weeklyEarning: null,
    monthlyEarning: null,
  };
};

export const handleRemoveMemberFromProject = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.projectId &&
      state.memberDetails.projects &&
      state.memberDetails.projects.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.projects = state.memberDetails.projects.filter(
        (project) => project.id !== userData.projectId
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleRemoveMemberFromTeam = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.teamId &&
      state.memberDetails.teams &&
      state.memberDetails.teams.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.teams = state.memberDetails.teams.filter(
        (team) => team.id !== userData.teamId
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleSetPaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleSetPaymentConfigurationSuccess = (state, action) => {
  createNotification("success", "Payment rate updated.", 3000);
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleSetPaymentConfigurationFailure = (state, action) => {
  createNotification(
    "error",
    "Could not add payment configuration. Please try again!",
    3000
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdatePaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleUpdatePaymentConfigurationSuccess = (state, action) => {
  createNotification(
    "success",
    "Member's payment configuration updated successfully.",
    3000
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleUpdatePaymentConfigurationFailure = (state, action) => {
  createNotification(
    "error",
    "Could not update payment configuration. Please try again!",
    3000
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdateTimesheetApprovalSettingsSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const membersList = JSON.parse(JSON.stringify(state.members));
    const details = JSON.parse(JSON.stringify(state.memberDetails));
    if (membersList && membersList.length > 0) {
      membersList.some((member) => {
        if (member.id === data.data.id) {
          member.require_timesheet_approval =
            data.data.require_timesheet_approval;
          return true;
        }
        return false;
      });
    }
    if (details && details.id && details.id === data.data.id) {
      details.require_timesheet_approval = data.data.require_timesheet_approval;
    }
    return {
      ...state,
      members: [...membersList],
      memberDetails: { ...details },
    };
  }
  return {
    ...state,
  };
};

export const clearMemberInvitedRequest = (state, action) => {
  return {
    ...state,
    memberInvited: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const updateManagerMemberDetailsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (!state.memberDetails) {
    return state;
  }

  if (data && data.status === 200) {
    // Ensure teams exist before updating
    const updatedTeams =
      state.memberDetails.teams?.map((team) => {
        if (team.team_membership_id === data.data.id) {
          return {
            ...team,
            is_manager: data.data.can_manage,
          };
        }
        return team;
      }) || [];

    return {
      ...state,
      memberDetails: {
        ...state.memberDetails,
        teams: updatedTeams,
      },
    };
  }

  return state;
};

// export const updateManagerMemberDetailsFailureHandler = (state, action) => {
//   if (action.payload.data === undefined) {
//     createNotification("error", "Something went wrong", 3000);
//     return {
//       ...state,
//     };
//   }
//   const {
//     payload: { data },
//   } = action;
//   if (data.status === 400) {
//     createNotification("error", "Failed!! please try again", 2000);
//   }
//   return {
//     ...state,
//   };
// };

// Action Handlers

const ACTION_HANDLERS = {
  [GET_MEMBERS]: membersListRequestHandler,
  [GET_MEMBERS_SUCCESS]: membersListRequestSuccessHandler,
  [GET_MEMBERS_FAILURE]: membersListRequestFailureHandler,

  [GET_INVITED_MEMBERS]: invitedMembersListRequestHandler,
  [GET_INVITED_MEMBERS_SUCCESS]: invitedMembersListRequestSuccessHandler,
  [GET_INVITED_MEMBERS_FAILURE]: invitedMembersListRequestFailureHandler,

  [INVITE_MEMBER]: inviteMemberRequestHandler,
  [INVITE_MEMBER_SUCCESS]: inviteMemberRequestSuccessHandler,
  [INVITE_MEMBER_FAILURE]: inviteMemberRequestFailureHandler,

  [DELETE_MEMBER_INVITATION]: deleteMemberInvitationRequestHandler,
  [DELETE_MEMBER_INVITATION_SUCCESS]:
    deleteMemberInvitationRequestSuccessHandler,
  [DELETE_MEMBER_INVITATION_FAILURE]:
    deleteMemberInvitationRequestFailureHandler,

  [GET_MEMBER_DETAILS]: membersDetailsRequestHandler,
  [GET_MEMBER_DETAILS_SUCCESS]: membersDetailsRequestSuccessHandler,
  [GET_MEMBER_DETAILS_FAILURE]: membersDetailsRequestFailureHandler,

  [GET_MEMBER_WEEKLY_EARNING]: memberWeeklyEarningRequestHandler,
  [GET_MEMBER_WEEKLY_EARNING_SUCCESS]: memberWeeklyEarningRequestSuccessHandler,
  [GET_MEMBER_WEEKLY_EARNING_FAILURE]: memberWeeklyEarningRequestFailureHandler,

  [GET_MEMBER_MONTHLY_EARNING]: memberMonthlyEarningRequestHandler,
  [GET_MEMBER_MONTHLY_EARNING_SUCCESS]:
    memberMonthlyEarningRequestSuccessHandler,
  [GET_MEMBER_MONTHLY_EARNING_FAILURE]:
    memberMonthlyEarningRequestFailureHandler,

  [UPDATE_MEMBER]: updateMemberRequestHandler,
  [UPDATE_MEMBER_SUCCESS]: updateMemberRequestSuccessHandler,
  [UPDATE_MEMBER_FAILURE]: updateMemberRequestFailureHandler,

  [REMOVE_MEMBER]: deleteMemberRequestHandler,
  [REMOVE_MEMBER_SUCCESS]: deleteMemberRequestSuccessHandler,
  [REMOVE_MEMBER_FAILURE]: deleteMemberRequestFailureHandler,

  [SET_PAYMENT_CONFIGURATION]: handleSetPaymentConfiguration,
  [SET_PAYMENT_CONFIGURATION_SUCCESS]: handleSetPaymentConfigurationSuccess,
  [SET_PAYMENT_CONFIGURATION_FAILURE]: handleSetPaymentConfigurationFailure,

  [UPDATE_PAYMENT_CONFIGURATION]: handleUpdatePaymentConfiguration,
  [UPDATE_PAYMENT_CONFIGURATION_SUCCESS]:
    handleUpdatePaymentConfigurationSuccess,
  [UPDATE_PAYMENT_CONFIGURATION_FAILURE]:
    handleUpdatePaymentConfigurationFailure,

  [UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS]:
    handleUpdateTimesheetApprovalSettingsSuccessHandler,

  [CLEAR_MEMBER_DETAILS_DATA]: clearMembersDetailsDataRequest,

  [DELETE_PROJECT_ASSIGNEE_SUCCESS]: handleRemoveMemberFromProject,

  [REMOVE_TEAM_MEMBER_SUCCESS]: handleRemoveMemberFromTeam,

  [CLEAR_MEMBER_INVITED]: clearMemberInvitedRequest,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,

  [UPDATE_TEAM_MEMBER_SUCCESS]: updateManagerMemberDetailsSuccessHandler,
  // [UPDATE_TEAM_MEMBER_FAILURE]: updateManagerMemberDetailsFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  inviteMemberLoading: false,
  deleteInvitationLoading: false,
  updateMemberLoading: false,
  paymentConfigurationLoading: false,
  deleteMemberLoading: false,
  members: [],
  memberDetails: null,
  invitedMembersList: [],
  weeklyEarning: null,
  monthlyEarning: null,
  paymentConfHistory: undefined,
  removed: false,
  archive: null,
  unarchive: null,
  invitationList: [],
  memberInvited: false,

  // pagination
  totalMembersCount: null,
  pageSize: null,

  reloadInvitedMembers: null,
  reloadMembers: null,
};

export default function memberReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
