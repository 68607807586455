import { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CheckBox } from "styledComponents/payroll";

import Tick from "../../assets/img/icons/tick.svg";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { ColoredButton, WhiteButton } from "../../styledComponents/buttons";
import { CommonFlex, CommonText } from "../../styledComponents/common";
import { ModalButtonSection } from "../../styledComponents/members";

const MemberDeleteModal = ({ isOpen, toggle, handleDelete, isLoading }) => {
  const [confirmed, setConfirmed] = useState(false);
  useEffect(() => {
    setConfirmed(false);
  }, [isOpen]);
  const debouncedHandleDelete = useCallback(() => {
    if (!isLoading) {
      handleDelete();
    }
  }, [handleDelete, isLoading]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to remove this member from your organization?
          All data related to this member will be deleted.
        </CommonText>
        <CommonText style={{ paddingLeft: `15px`, paddingTop: "20px" }}>
          This action cannot be undone!
        </CommonText>
        <CommonFlex style={{ paddingLeft: `15px`, paddingTop: "30px" }}>
          <CheckBox onClick={() => setConfirmed(!confirmed)}>
            {confirmed && (
              <img src={Tick} alt="tick" width="20px" height="20px" />
            )}
          </CheckBox>{" "}
          <CommonText>I agree</CommonText>
        </CommonFlex>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            disabled={!confirmed}
            padding={isLoading ? "0 15px" : "0 30px"}
            onClick={() => confirmed && debouncedHandleDelete()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default MemberDeleteModal;
