import { useState, useEffect, Fragment } from "react";
import Select from "../../components/ReactSelectDropdown";
import MembersDropdownStyle from "../../components/DropdownStyle/MembersDropdownStyle";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";

import {
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  InputField,
  InputTextArea,
  Container,
  RedStar,
  FormAlert,
} from "../../styledComponents/common";
import { PrimaryButton } from "../../styledComponents/buttons";
import { FixedPaymentContainer } from "../../styledComponents/payroll";

import { checkUrl, checkHtml } from "../../utils/helper";

import { currenciesWithSymbols } from "../../utils/currencies";

import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const FixedAmount = (props) => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [membersList, setMembersList] = useState([]);

  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Active Projects)",
  });
  const [amountValue, setAmountValue] = useState("");
  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    return () => {
      props.clearOneTimePaymentData();
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      setSelectedProject({ value: null, label: "(All Active Projects)" });
      setSelectedMember("");

      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.membersList && props.membersList.length > 0) {
      setMembersList(props.membersList);
    } else if (props.membersList && props.membersList.length === 0) {
      setMembersList([]);
    }
  }, [props.membersList]);

  useEffect(() => {
    if (props.oneTimePaymentData && props.oneTimePaymentData.paid_at) {
      props.setTabSelect("history");
      props.setHistoryType("fixed_amount");
    }
  }, [props.oneTimePaymentData]);

  const onProjectChange = (e) => {
    setSelectedProject(e);
    let errors = saveErrors;
    errors["project"] = "";
    setSaveErrors(errors);
  };
  const onAmountChange = (e) => {
    const { value } = e.target;
    setAmountValue(value);
    let errors = saveErrors;
    errors["amount"] = "";
    setSaveErrors(errors);
  };

  const handleSelectCurrency = (e) => {
    setCurrency(e);
    let errors = saveErrors;
    errors["currency"] = "";
    setSaveErrors(errors);
  };

  const onProjectDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    let errors = saveErrors;
    errors["note"] = "";
    setSaveErrors(errors);
  };

  const updatePerson = (person) => {
    setSelectedMember(person);
    setSelectedProject({ value: null, label: "(All Active Projects)" });
    let errors = saveErrors;
    errors["member"] = "";
    setSaveErrors(errors);
  };

  const handleSubmit = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          user_id: selectedMember.id,
          paid_amt: parseFloat(amountValue).toFixed(2) * 100,
          note: description,
        };
        if (currency && currency.value) {
          payload.currency = currency.value;
        }
        if (selectedProject && selectedProject.value) {
          payload.project_id = selectedProject.value;
        }
        // console.log(payload);
        props.sendOneTimePayment(payload);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!selectedMember) {
      formIsValid = false;
      saveErrors["member"] = "Please select a member.";
    }
    if (!amountValue) {
      formIsValid = false;
      saveErrors["amount"] = "Please enter an amount.";
    } else if (amountValue && amountValue <= 0) {
      formIsValid = false;
      saveErrors["amount"] = "Amount must be greater than 0.";
    }
    if (!description.trim()) {
      formIsValid = false;
      saveErrors["note"] = "Note is required for one time payment.";
    } else if (checkUrl(description)) {
      formIsValid = false;
      saveErrors["note"] = "Note can not contain url.";
    } else if (checkHtml(description)) {
      formIsValid = false;
      saveErrors["note"] = "Invalid input in note.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <Container padding="30px">
      <CardTitle margin=" 0 0 20px 0">One Time Payment</CardTitle>
      <FixedPaymentContainer>
        <InputWithLabelSection>
          <InputLabel>
            Member <RedStar>*</RedStar>
          </InputLabel>
          <div style={{ marginRight: `auto` }}>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={updatePerson}
              membersList={membersList}
              clearAssignee={() => setSelectedMember(null)}
            />
          </div>
        </InputWithLabelSection>
        {saveErrors && saveErrors.member && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.member}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>Project</InputLabel>
          <ProjectDropdown
            selectedOrganization={props.selectedOrganization}
            selectedProject={selectedProject}
            selectProject={onProjectChange}
            selectedMember={selectedMember}
            style={MembersDropdownStyle({ minHeight: "40px" })}
            addAllProjects={true}
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.project && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.project}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>
            Amount <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="number"
            placeholder="0.00"
            min={0}
            step="0.01"
            value={amountValue}
            onChange={(e) => onAmountChange(e)}
            error={
              saveErrors &&
              saveErrors["amount"] &&
              saveErrors["amount"] !== undefined
            }
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.amount && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.amount}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>Currency</InputLabel>
          <Select
            isSearchable
            value={currency}
            options={currenciesWithSymbols}
            onChange={(e) => {
              handleSelectCurrency(e);
            }}
            styles={MembersDropdownStyle({ minHeight: "40px" })}
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.currency && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.currency}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>
            Note <RedStar>*</RedStar>
          </InputLabel>
          <InputTextArea
            text="Description"
            cols="40"
            rows="5"
            height="100px"
            value={description}
            placeholder="Keep record about payment information and other stuffs..."
            onChange={(e) => onProjectDescriptionChange(e)}
            error={
              saveErrors &&
              saveErrors["note"] &&
              saveErrors["note"] !== undefined
            }
          ></InputTextArea>
        </InputWithLabelSection>
        {saveErrors && saveErrors.note && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.note}</FormAlert>
        )}
        <PrimaryButton
          onClick={() => handleSubmit()}
          padding={props.paymentIsLoading ? "0 40px" : "0 35px"}
          width="fit-content"
        >
          {props.paymentIsLoading ? (
            <ButtonTextLoader loadingText="Sending" fontSize="13px" />
          ) : (
            "Send Payment"
          )}
        </PrimaryButton>
      </FixedPaymentContainer>
    </Container>
  );
};

export default FixedAmount;
