import { useState, useEffect, Fragment, useRef } from "react";
import styled from "styled-components";
import MembersDropdownStyle from "../../../components/DropdownStyle/MembersDropdownStyle";
import { validateEmail } from "../../../utils/helper";

import {
  MemberInvitationContainer,
  RedStar,
} from "../../../styledComponents/members";
import {
  PageTitle,
  CardTitle,
  Container,
  InputWithLabelSection,
  InputLabel,
  ContentHeader,
  CommonFlex,
  CommonText,

} from "../../../styledComponents/common";
import {
  WhiteButton,
  PrimaryButton,
  ButtonIcon,
} from "../../../styledComponents/buttons";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

import ProjectDropdown from "../../../components/CommonFilter/ProjectDropdown";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import SubscriptionCancellationModal from "../SubscriptionCancellationModal";
import { GeneralInfoItem } from "../../../styledComponents/clients";
import { DeleteButton } from "../../../styledComponents/integrations";

import crossIcon from "../../../assets/img/icons/cross_black.svg";

const ButtonContainer = styled.div`
  display: flex;
`;
const EmailAlert = styled.p`
  margin: 10px 0px;
  color: ${({ theme }) => theme.colors.main.error};
  font-size: ${({ theme }) => theme.font.pageText.size};
`;
const EmailText = styled.div`
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;

export const EmailBox = styled.div`
  width: 100%;
  height: 180px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  overflow-y: auto; /* Enable vertical scroll */

  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;

const ProjectNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ProjectNameDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-size: 13px;
  margin-right: 8px;
`;
const InvitationSentText = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const EmailEntry = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-right: 7px;
  margin-bottom: 5px;
  padding: 2% 5px;
  background-color: #dee3eb;
  border-radius: 5px;
  font-size: 12px;

  button {
    background-color: #ffffff;
    color: #495872;
    border: none;
    border-radius: 50%;
    margin-left: 7px;
    cursor: pointer;
    font-size: 10px;
  }
`;

export const InputEmail = styled.input`
  border: none;
  outline: none;
  cursor: text;
  width: 100%;
  /* padding: 5px 10px; */
  box-sizing: border-box;
  font-size: inherit;
  color: inherit;
`;

const UpgradeSectionForSolo = ({ history }) => {
  return (
    <CommonFlex direction="column" alignItems="center" padding="60px 30px">
      <CommonText title name fontSize="24px" margin="0 0 20px">
        Upgrade your plan to invite more members
      </CommonText>
      <CommonText
        $label
        fontWeight="500"
        margin="0 150px 40px"
        fontSize="16px"
        style={{ textAlign: "center" }}
      >
        You can not have more than one member in Solo plan. Please, upgrade your
        plan to add your team members.
      </CommonText>
      <PrimaryButton onClick={() => history.push("/user/subscription")}>
        Upgrade
      </PrimaryButton>
    </CommonFlex>
  );
};

const MemberInvitation = (props) => {
  const [emailList, setEmailList] = useState([]);
  const [selectedProjectList, setSelectedProjectList] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [invalidEmailInput, setInvalidEmailInput] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [
    isSubscriptionCancelModalVisible,
    setIsSubscriptionCancelModalVisible,
  ] = useState(false);
  const inputRef = useRef(null);

  const {
    selectedOrganization,
    clearMemberInvited,
    projectList,
    memberInvited,
    inviteMember,
    history,
    projectIsLoading,
    inviteMemberLoading,
  } = props;

  useEffect(() => {
    if (selectedOrganization?.subscription?.status === "cancelled") {
      setIsSubscriptionCancelModalVisible(true);
    }
  }, [selectedOrganization?.subscription?.status]);

  useEffect(() => {
    clearMemberInvited();

    return () => {
      clearMemberInvited();
    };
  }, []);

  useEffect(() => {
    if (memberInvited) {
      setEmailList([...emailList]);
      setSelectedProjectList([...selectedProjectList]);
      history.push("/user/members?tab=invited");
    }
  }, [memberInvited]);

  const sendInvitaion = () => {
    // When a valid email is written but no space, comma is pressed
    let updatedEmailList = [...emailList];

    if (emailInput) {
      if (!checkError(emailInput)) return;
      updatedEmailList.push(emailInput);
      // setEmailList([...emailList, emailInput])
      setEmailInput("");
    }

    if (updatedEmailList.length <= 0) {
      checkError(null);
      return;
    }
    if (selectedOrganization?.id) {
      const projectId = selectedProjectList.map(({ value }) => value);
      let payload = {
        organization_id: selectedOrganization.id,
        emails: updatedEmailList,
        project_ids: projectId,
      };
      inviteMember(payload);
    }
  };

  const removeEmail = (index) => {
    const updatedEmailList = [...emailList];
    updatedEmailList.splice(index, 1);
    setEmailList(updatedEmailList);
  };

  useEffect(() => {
    if (emailInput.match(/,|\s/)) {
      setInvalidEmailInput("");
      const emails = emailInput.split(/,|\n|\s/);

      // Process each email
      emails.forEach((email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail) {
          addEmail(trimmedEmail);
        }
      });
    }
  }, [emailInput]);

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "," || e.key === " ") {
      e.preventDefault();
      setInvalidEmailInput("");
      const emails = emailInput.split(/,|\n/);

      // Process each email
      emails.forEach((email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail) {
          addEmail(trimmedEmail);
        }
      });
    }
  };

  const addEmail = (newEmail) => {
    if (newEmail.trim() !== "") {
      if (checkError(newEmail)) {
        setEmailInput("");
        setEmailList((prevList) => [...prevList, newEmail.trim()]);
      } else {
        setInvalidEmailInput((prevInvalidEmailInput) => [
          ...prevInvalidEmailInput,
          newEmail,
        ]);
      }
    }
  };
  
  const checkError = (item) => {
    let saveErrors = {};
    let isValid = true;
    if (!item) {
      saveErrors["email"] = "Please type an email address.";
      isValid = false;
    } else if (!validateEmail(item)) {
      saveErrors["email"] = "Please give a valid email address.";
      isValid = false;
    } else if (emailList.includes(item.trim())) {
      saveErrors["email"] = "This email is already in the list.";
      isValid = false;
    }
    setSaveErrors(saveErrors);
    return isValid;
  };

  const onProjectChange = (e) => {
    setSelectedProjectList(e);
  };

  const handleSubscriptionUpgrade = () => {
    history.push("/user/subscription");
  };

  return (
    <div className="content">
      <SubscriptionCancellationModal
        isOpen={isSubscriptionCancelModalVisible}
        toggle={() => history.goBack()}
        handleUpgrade={handleSubscriptionUpgrade}
      />
      <PageTitle>Members</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/members")}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {selectedOrganization &&
        selectedOrganization.plan &&
        selectedOrganization.plan.name === "Solo" ? (
          <UpgradeSectionForSolo history={history} />
        ) : projectIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle>Member Invitation</CardTitle>
            <MemberInvitationContainer>
              <InputWithLabelSection gap="10px">
                <InputLabel>
                  Email <RedStar>*</RedStar>
                </InputLabel>
                <>
                  <EmailBox onClick={() => inputRef.current.focus()}>
                    {emailList.map((email, index) => (
                      <EmailEntry key={index}>
                        {email}
                        <DeleteButton
                          radius="50%"
                          onClick={() => removeEmail(index)}
                        >
                          <ButtonIcon
                            src={crossIcon}
                            size="16px"
                            alt="delete"
                            margin="0"
                          />
                        </DeleteButton>
                      </EmailEntry>
                    ))}
                    <InputEmail
                      ref={inputRef}
                      placeholder={
                        emailList?.length > 0
                          ? "Add more email..."
                          : "Write email address here..."
                      }
                      value={emailInput}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                    />
                  </EmailBox>
                  <GeneralInfoItem backColor={8}>
                    <CommonText>
                      You can add multiple email addresses by separating with
                      comma or space
                    </CommonText>
                  </GeneralInfoItem>
                </>
              </InputWithLabelSection>
              <InputWithLabelSection
                gap="10px"
                style={{ marginBottom: "auto" }}
              >
                <InputLabel>Projects (optional)</InputLabel>
                <ProjectDropdown
                  multiple
                  selectedOrganization={selectedOrganization}
                  selectedProject={selectedProjectList}
                  placeholder="Select Projects"
                  selectProject={(e) => {
                    onProjectChange(e);
                  }}
                  style={MembersDropdownStyle()}
                />
              </InputWithLabelSection>
            </MemberInvitationContainer>

            {saveErrors?.email && <EmailAlert>{saveErrors.email}</EmailAlert>}
            {emailList?.length > 0 && (
              <CommonText margin="10px 0">
                Inviting{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {emailList.length == 1
                    ? "1 email"
                    : `${emailList.length} emails`}
                  {""}
                </span>
                .
              </CommonText>
            )}
            <CommonFlex
              margin={saveErrors?.email ? "25px 0 0 0" : "40px 0 0 0"}
            >
              <PrimaryButton
                padding={inviteMemberLoading && "0 25px"}
                onClick={!inviteMemberLoading ? () => sendInvitaion() : ""}
              >
                {inviteMemberLoading ? (
                  <ButtonTextLoader
                    loadingText="Sending Invitation"
                    fontSize="13px"
                  />
                ) : (
                  "Send Invitation"
                )}
              </PrimaryButton>
            </CommonFlex>

            <CommonText margin="30px 0 0 0">
              or you can{" "}
              <span
                style={{
                  color: "#2268EF",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
                onClick={() => history.push("/user/member-invitation-csv")}
              >
                Upload a CSV
              </span>
            </CommonText>
          </>
        )}
      </Container>
    </div>
  );
};

export default MemberInvitation;
