import { InputField } from "../../styledComponents/common";
import ClearFilterButton from "./clearFilterButton";

const InputWithClearButton = ({
  searchTerm,
  onChange,
  placeHolder,
  clearFunction,
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
      }}
    >
      <InputField
        type="text"
        placeholder={placeHolder}
        value={searchTerm}
        onChange={(e) => onChange(e)}
        style={{ minWidth: "260px" }}
      />{" "}
      {searchTerm && (
        <div
          style={{
            position: "absolute",
            right: "12px",
            top: "27%",
            zIndex: "1",
          }}
        >
          <ClearFilterButton onClick={() => clearFunction()} />
        </div>
      )}
    </div>
  );
};

export default InputWithClearButton;
