import React, { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import {
  InputWithLabelSection,
  InputField,
  InputLabel,
  CommonGrid,
  ColoredText,
  CommonText,
  CommonFlex,
  RedStar,
} from "../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../styledComponents/buttons";
import {
  EmailValue,
  ProfileGrid,
  EmailDiv,
  DefaultEmailBadge,
  EmailOptionButton,
  FormFeedback,
  AddEmailDiv,
  NotVerified,
  AlertIcon,
} from "./profileStyles";

import {
  handleValidation,
  validateEmail,
  checkUrl,
  checkHtml,
} from "../../utils/helper";
import { timezones } from "../../utils/timezone";

import userFormFields from "./userFormFields";
import UserProfileEmailModal from "./UserProfileEmailModal";

import "react-phone-number-input/style.css";
import DropdownStyles from "../../components/DropdownStyle/DropdownStyle";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import addIcon from "../../assets/img/icons/plus.svg";
import alertIcon from "../../assets/img/icons/alert.svg";
import { clearLocalStorage } from "../../utils/handleLogout";

const UserProfileEdit = (props) => {
  const [updatedParamsState, setUpdatedParamsState] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    timezone: "",
  });
  const [filesState, setFilesState] = useState("");
  const [addEmailState, setAddEmailState] = useState(false);
  const [newEmailState, setNewEmailState] = useState("");
  const [emailErrorState, setEmailErrorState] = useState("");
  const [emailDeleteInfoState, setEmailDeleteInfoState] = useState(null);
  const [makeEmailDefaultInfoState, setMakeEmailDefaultInfoState] =
    useState(null);
  const [verificationSentState, setVerificationSentState] = useState(false);
  const [validationMessagesState, setValidationMessagesState] = useState({});
  const [prevProps, setPrevProps] = useState(props);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const filterOptions = (options, filterString, values) => {
    return options.filter(
      (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
    );
  };

  useEffect(() => {
    setPrevProps(props);
    let updatedParams = {
      phone: props.phone,
      first_name: props.first_name,
      last_name: props.last_name ? props.last_name : ``,
      timezone: {
        label: props.timezone,
        value: props.timezone,
      },
    };
    setFirstName(props.first_name);
    setLastName(props.last_name);
    setUpdatedParamsState(updatedParams);
  }, [props.phone, props.first_name, props.last_name, props.timezone]);

  useEffect(() => {
    if (makeEmailDefaultInfoState != null) {
      confirmMakeEmailDefault();
    }
  }, [makeEmailDefaultInfoState]);
  useEffect(() => {
    if (props.requestUserEmails && !props.addEmailLoading) {
      setAddEmailState(false);
      setNewEmailState("");
    }
  }, [props.requestUserEmails, props.addEmailLoading]);

  useEffect(() => {
    const { requestUserEmails, getUserEmails } = props;
    if (
      requestUserEmails !== prevProps.requestUserEmails &&
      requestUserEmails === true
    ) {
      getUserEmails();
    }
  }, [props.requestUserEmails, props.getUserEmails]);

  useEffect(() => {
    if (
      props.first_name !== prevProps.first_name ||
      props.last_name !== prevProps.last_name ||
      props.timezone !== prevProps.timezone
    ) {
      let updatedParams = {
        first_name: props.first_name,
        last_name: props.last_name ? props.last_name : ``,
        timezone: { label: props.timezone, value: props.timezone },
      };
      setUpdatedParamsState(updatedParams);
    }
  }, [props.first_name, props.last_name, props.timezone]);

  const checkError = () => {
    let formIsValid = true;
    const updatedParams = { ...updatedParamsState };
    const validationMessages = { ...validationMessagesState };

    if (!updatedParams.first_name) {
      formIsValid = false;
      validationMessages.first_name = "Please enter first name.";
    } else if (checkUrl(updatedParams.first_name)) {
      formIsValid = false;
      validationMessages.first_name = "First name can not contain url.";
    } else if (checkHtml(updatedParams.first_name)) {
      formIsValid = false;
      validationMessages.first_name = "Invalid input in first name.";
    }

    if (checkUrl(updatedParams.last_name)) {
      formIsValid = false;
      validationMessages.last_name = "First name can not contain url.";
    } else if (checkHtml(updatedParams.last_name)) {
      formIsValid = false;
      validationMessages.last_name = "Invalid input in last name.";
    }
    setValidationMessagesState({ ...validationMessages });
    return formIsValid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const files = filesState;
    const updatedParams = updatedParamsState;
    const validationMessages = validationMessagesState;

    const { phone, ...paramData } = updatedParams;
    for (let name in paramData) {
      setTimeout(() => handleValidations(name, paramData[name]), 200);
    }
    if (
      files ||
      (updatedParams.first_name &&
        !validationMessages.first_name &&
        !validationMessages.last_name &&
        !validationMessages.phone)
    ) {
      if (checkError()) {
        const { handleSave } = props;
        let payload = {};
        payload.first_name = updatedParams.first_name;
        payload.last_name = updatedParams.last_name;

        if (updatedParams.phone) {
          payload.phone = updatedParams.phone;
        } else {
          payload.phone = null;
        }
        if (updatedParams.timezone) {
          payload.timezone = updatedParams.timezone.value;
          payload.timezoneOffset = updatedParams.timezone.offset;
        } else {
          payload.timezone = null;
        }
        if (files) {
          payload.image = files.base64;
        }
        handleSave(payload);
      }
    }
  };

  const handleValidations = (name, value) => {
    if (name !== "timezone" && name !== "last_name") {
      const validationMessages = { ...validationMessagesState };
      const el = userFormFields.find((v) => {
        return v.fieldName === name;
      });
      const result = handleValidation(name, el.type, value, el.options);
      let validationMessagesLast = { ...validationMessages };

      if (result.isValid) {
        delete validationMessagesLast[name];
      } else {
        validationMessagesLast = { ...validationMessagesLast, [name]: result };
      }
      setValidationMessagesState(validationMessagesLast);
    }
  };
  //need to re check
  const handleChange = (e, type) => {
    const updatedParams = { ...updatedParamsState };
    const name = !e.target && type ? type : e.target.name;
    const value = e.target ? e.target.value : e.value;
    //alert(name, value, updatedParams);
    handleValidations(name, value);
    if (type === "timezone") {
      updatedParams.timezone = e;
    } else {
      switch (name) {
        case "first_name":
          updatedParams.first_name = value;
          break;
        case "last_name":
          updatedParams.last_name = value;
          break;
        case "phone":
          updatedParams.phone = value;
          break;
      }
    }

    setUpdatedParamsState(updatedParams);
  };
  //Email Functions
  const handleAddEmail = () => {
    setAddEmailState(true);
    setVerificationSentState(false);
  };

  const onNewEmailChange = (e) => {
    setNewEmailState(e.target.value);
    setEmailErrorState("");
  };

  const addNewEmail = () => {
    const newEmail = newEmailState;
    const addEmail = props.addEmail;
    if (newEmail === "") {
      setEmailErrorState("Email Can't be empty");
    } else if (!validateEmail(newEmail)) {
      setEmailErrorState("please enter a valid email address");
    } else {
      let payload = {
        email: newEmail,
      };
      addEmail(payload);
      // setNewEmailState("");
      // setAddEmailState(false);
      setVerificationSentState(true);
      props.getUserEmails();
    }
  };

  const cancelAddEmail = () => {
    setAddEmailState(false);
  };

  const handleEmailDelete = (id) => {
    setEmailDeleteInfoState(id);
  };

  const cancelEmailDelete = () => {
    setEmailDeleteInfoState(null);
  };

  const confirmEmailDelete = () => {
    const emailDeleteInfo = emailDeleteInfoState;
    const deleteEmail = props.deleteEmail;
    const payload = {
      id: emailDeleteInfo,
    };
    deleteEmail(payload);
    setEmailDeleteInfoState(null);
  };

  const makeEmailDefault = (item) => {
    setMakeEmailDefaultInfoState(item);
  };

  const confirmMakeEmailDefault = () => {
    const makeEmailDefaultInfo = makeEmailDefaultInfoState;
    const { makeEmailDefault, history, loginProfileCreatedReset } = props;
    const payload = {
      id: makeEmailDefaultInfo.id,
      email: makeEmailDefaultInfo.email,
    };
    makeEmailDefault(payload);
    setMakeEmailDefaultInfoState(null);
    clearLocalStorage();
    history.push("/auth/login");
    loginProfileCreatedReset();
  };

  const cancelMakeEmailDefault = () => {
    setMakeEmailDefaultInfoState(null);
  };
  const toggle = () => {
    setEmailDeleteInfoState(null);
  };
  return (
    <>
      <UserProfileEmailModal
        isOpen={emailDeleteInfoState != null}
        toggle={toggle}
        confirmEmailDelete={confirmEmailDelete}
      />
      <div style={{ marginTop: "20px" }}>
        <ProfileGrid alignItems="start">
          <InputWithLabelSection>
            <CommonFlex gap="0px">
              <InputLabel>Your First Name</InputLabel> <RedStar>*</RedStar>
            </CommonFlex>{" "}
            <InputField
              type="text"
              name="first_name"
              id="firstName"
              value={firstName}
              defaultValue={updatedParamsState.first_name}
              borderColor={validationMessagesState.first_name && "#fe5969"}
              onChange={(e) => {
                setFirstName(e.target.value);
                handleChange(e);
              }}
            />
            {validationMessagesState.first_name && (
              <FormFeedback>
                {validationMessagesState.first_name.message}
              </FormFeedback>
            )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Your Last Name</InputLabel>
            <InputField
              type="text"
              name="last_name"
              id="lastName"
              value={lastName}
              defaultValue={updatedParamsState.last_name}
              borderColor={validationMessagesState.last_name && "#fe5969"}
              onChange={(e) => {
                setLastName(e.target.value);
                handleChange(e);
              }}
            />
            {/* {validationMessagesState.last_name && (
                <FormFeedback>
                  {validationMessagesState.last_name.message}
                </FormFeedback>
              )} */}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>Timezone</InputLabel>
            <Select
              value={
                updatedParamsState.timezone
                  ? {
                      label: updatedParamsState.timezone.value,
                      value: updatedParamsState.timezone.value,
                    }
                  : props.timezone
                  ? { label: props.timezone, value: props.timezone }
                  : ""
              }
              name="timezone"
              placeholder="Select timezone"
              options={timezones}
              filterOptions={filterOptions}
              onChange={(e) => handleChange(e, "timezone")}
              styles={DropdownStyles(null)}
            />
          </InputWithLabelSection>
          {/* <InputWithLabelSection>
            <InputLabel>Country</InputLabel>
            <Select
              name="countries"
              id="exampleSelectCountry"
              placeholder="Select Country"
              filterOptions={filterOptions}
            />
          </InputWithLabelSection> */}
        </ProfileGrid>
        <EmailDiv>
          <InputWithLabelSection>
            <InputLabel>Email Address</InputLabel>
            <div>
              {props.emails &&
                props.emails.length > 0 &&
                props.emails.map((item, i) => {
                  return (
                    <ProfileGrid
                      alginItems="start"
                      key={item.id}
                      margin="0 0 15px 0"
                    >
                      <EmailValue>{item.email}</EmailValue>
                      {item.default ? (
                        <DefaultEmailBadge>Default</DefaultEmailBadge>
                      ) : !item.verified ? (
                        <ProfileGrid emailOption alginItems="start">
                          <NotVerified>
                            <AlertIcon src={alertIcon} alt="" />
                            <CommonText fontSize="12px" color="#fab242">
                              Not Verified
                            </CommonText>
                          </NotVerified>
                          {!item.default && (
                            <EmailOptionButton
                              deleteEmail
                              onClick={() => handleEmailDelete(item.id)}
                            >
                              Delete
                            </EmailOptionButton>
                          )}
                        </ProfileGrid>
                      ) : (
                        <ProfileGrid emailOption alginItems="start">
                          <EmailOptionButton
                            onClick={() => makeEmailDefault(item)}
                          >
                            Make Default
                          </EmailOptionButton>
                          {!item.default && (
                            <EmailOptionButton
                              deleteEmail
                              onClick={() => handleEmailDelete(item.id)}
                            >
                              Delete
                            </EmailOptionButton>
                          )}
                        </ProfileGrid>
                      )}
                    </ProfileGrid>
                  );
                })}
              {addEmailState ? (
                <>
                  <ProfileGrid>
                    <AddEmailDiv>
                      <InputField
                        height="38px"
                        type="text"
                        name="last_name"
                        id="lastName"
                        placeholder="Add a new email…"
                        value={newEmailState}
                        onChange={(e) => onNewEmailChange(e)}
                        borderColor={emailErrorState && "#fe5969"}
                      />
                      {emailErrorState && emailErrorState !== "" && (
                        <FormFeedback>{emailErrorState}</FormFeedback>
                      )}
                    </AddEmailDiv>
                    <CommonFlex gap="12px">
                      <WhiteButton
                        type="cancel"
                        width="110px"
                        height="38px"
                        onClick={() => cancelAddEmail()}
                      >
                        Cancel
                      </WhiteButton>
                      <PrimaryButton
                        padding="0 15px"
                        width="110px"
                        height="38px"
                        disabled={!newEmailState}
                        onClick={() => addNewEmail()}
                      >
                        Add Email
                      </PrimaryButton>
                    </CommonFlex>
                  </ProfileGrid>
                </>
              ) : (
                <CommonGrid
                  columns="20px auto"
                  gap="8px"
                  margin="20px 0 0 0"
                  justifyContent="start"
                  alignItem="center"
                  style={{ cursor: `pointer` }}
                  onClick={() => handleAddEmail()}
                >
                  <img src={addIcon} alt="plus" />
                  <ColoredText fontSize="14px" primary>
                    Add another email
                  </ColoredText>
                </CommonGrid>
              )}
            </div>
          </InputWithLabelSection>
        </EmailDiv>
        <ProfileGrid margin="25px 0 0 0">
          <PrimaryButton
            width="fit-content"
            padding={props.updateIsLoading ? "0 25px" : "0 50px"}
            onClick={handleSave}
          >
            {props.updateIsLoading ? (
              <ButtonTextLoader loadingText="Saving.." fontSize="13px" />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        </ProfileGrid>
      </div>
    </>
  );
};

export default UserProfileEdit;
