import { useState, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import {
  ModalButtonSection,
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  CardTitle,
  RedStar,
  CommonText,
  InputTextArea,
  InputWithLabelSection,
  InputLabel,
  FormAlert,
  CloseButton,
  CloseImg,
} from "../../../styledComponents/common";
import {
  PrimaryButton,
  WhiteButton,
  ModalDeleteButton,
} from "../../../styledComponents/buttons";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import { checkUrl, checkHtml, getFullName } from "../../../utils/helper";

import CrossIcon from "../../../assets/img/icons/cross_black.svg";

const colorList = ["#9A96F9", "#F9828B", "#FECF34"];

const NotesModal = styled(Modal)`
  max-width: 600px;
  margin-top: 12vh;
  .modal-content {
    border-radius: ${({ theme }) => theme.card.borderRadius};
    max-height: 75vh;
    overflow-y: auto;
  }
`;
const NotesModalHeader = styled(ModalHeader)`
  padding: 20px;
  position: relative;
  border-bottom: none;
`;
const NotesModalBody = styled(ModalBody)`
  padding: 0px;
`;

const NoteContainer = styled.div`
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
`;
const TimeSlotContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SerialNo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  font-size: 12px;
  background: ${({ index }) => colorList[index % 3]};
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
`;
const TimeSlot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 10px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background: ${({ index }) => colorList[index % 3]};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.white};
`;

const NoteTextContainer = styled.div`
  margin-top: ${({ marginTop }) => marginTop || `15px`};
`;
const NoteTitleAndCreatedByDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const CreatedByDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0px 12px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: #dddcfb;
  color: ${({ theme }) => theme.colors.main.purpleMedium};
  margin-left: 10px;
`;

const SingleNote = ({
  index,
  noteInfo,
  project,
  task,
  startTime,
  endTime,
  noteMember,
}) => {
  return (
    <NoteContainer>
      <TimeSlotContainer>
        <SerialNo index={index}>{index + 1}</SerialNo>
        <TimeSlot index={index}>{`${
          startTime
            ? startTime.format("hh:mm A")
            : moment
                .unix(noteInfo.timeframe && noteInfo.timeframe.start_timestamp)
                .format("hh:mm A")
        } - ${
          endTime
            ? endTime.format("hh:mm A")
            : moment
                .unix(noteInfo.timeframe && noteInfo.timeframe.end_timestamp)
                .format("hh:mm A")
        }`}</TimeSlot>
      </TimeSlotContainer>
      <NoteTextContainer>
        <CommonText title>
          {project ? project.name : noteInfo.project && noteInfo.project.name}
        </CommonText>
        {task && <CommonText>{task.name || "No Task"}</CommonText>}
        {noteInfo.task && (
          <CommonText fontSize="12px">
            {noteInfo.task.name || "No Task"}
          </CommonText>
        )}
      </NoteTextContainer>
      <NoteTextContainer marginTop={"20px"}>
        <NoteTitleAndCreatedByDiv>
          <CommonText $label>Note</CommonText>
          {noteInfo && noteInfo.created_by && (
            // noteMember &&
            // noteInfo.created_by.id !== noteMember.id &&
            <CreatedByDiv>
              {`Added by ${getFullName(noteInfo.created_by)}`}
            </CreatedByDiv>
          )}
        </NoteTitleAndCreatedByDiv>

        <CommonText fontSize="13px">{noteInfo.content}</CommonText>
      </NoteTextContainer>
    </NoteContainer>
  );
};

export const ShowNotesModal = ({
  isOpen,
  toggle,
  notes,
  notesType,
  user,
  noteMember,
  project,
  task,
  startTime,
  endTime,
  isLoading,
}) => {
  return (
    <NotesModal isOpen={isOpen} toggle={toggle}>
      <NotesModalHeader>
        <CardTitle fontSize="16px">
          {notesType === "all" ? `All ` : ``}Notes
          {`${
            user
              ? ` of ${getFullName(user)}`
              : noteMember
              ? ` of ${noteMember.name}`
              : ``
          }`}
        </CardTitle>
        <CommonText margin="5px 0 0 0" fontSize="13px">
          {startTime ? startTime.format("dddd, MMMM DD, YYYY") : null}
        </CommonText>
        <CloseButton onClick={toggle}>
          <CloseImg src={CrossIcon} alt="" />
        </CloseButton>
      </NotesModalHeader>
      <NotesModalBody>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {notes &&
              notes.length > 0 &&
              notes.map((note, index) => (
                <SingleNote
                  key={index}
                  index={index}
                  noteInfo={note}
                  project={project}
                  task={task}
                  startTime={startTime}
                  endTime={endTime}
                  noteMember={noteMember ? noteMember : user}
                />
              ))}
            {notes && notes.length === 0 && (
              <NoDataComponent
                title="No notes!"
                subtitle="No notes found for this user in the given date."
                padding="30px 0"
                animationSize="200px"
                titleFontSize="14px"
                subTitleFontSize="13px"
              />
            )}
          </>
        )}
      </NotesModalBody>
    </NotesModal>
  );
};

export const AddNoteModal = ({
  isOpen,
  toggle,
  saveNote,
  startTime,
  endTime,
  isLoading,
}) => {
  const [note, setNote] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  const onNoteValueChange = (e) => {
    // setSaveErrors({});
    setNote(e.target.value);
    setSaveErrors({});
  };

  const toggleModal = () => {
    toggle();
    setNote("");
    setSaveErrors({});
  };

  const checkError = () => {
    const saveErrors = {};
    let formIsValid = true;
    if (!note.trim()) {
      formIsValid = false;
      saveErrors["note"] = "Note can not be empty.";
    } else if (checkUrl(note)) {
      formIsValid = false;
      saveErrors["note"] = "Note can not contain url.";
    } else if (checkHtml(note)) {
      formIsValid = false;
      saveErrors["note"] = "Invalid note text.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSaveNote = (note) => {
    if (checkError()) {
      saveNote(note);
    }
  };
  useEffect(() => {
    setNote("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} style={{ marginTop: `20vh` }}>
      <ModalBody style={{ padding: `30px` }}>
        <ActivityTextSection gap="30px">
          <FlexSection>
            <CardTitle fontSize="16px">Add Note</CardTitle>
            <CommonText>{`${startTime.format("h:mm a")} - ${endTime.format(
              "h:mm a"
            )}`}</CommonText>
          </FlexSection>
          <InputTextArea
            text="Description"
            cols="40"
            rows="6"
            value={note}
            placeholder="Write your note here..."
            style={{ borderRadius: `6px`, padding: "10px" }}
            height="120px"
            onChange={(e) => onNoteValueChange(e)}
            error={
              saveErrors &&
              saveErrors["note"] &&
              saveErrors["note"] !== undefined
            }
          ></InputTextArea>
          {saveErrors &&
            saveErrors["note"] &&
            saveErrors["note"] !== undefined && (
              <FormAlert margin="-20px 0 0 5px">{saveErrors["note"]}</FormAlert>
            )}
        </ActivityTextSection>
        <ModalButtonSection style={{ paddingTop: "30px" }}>
          <WhiteButton type="cancel" onClick={toggleModal}>
            Cancel
          </WhiteButton>
          <PrimaryButton disabled={!note} onClick={() => handleSaveNote(note)}>
            {isLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              "Save Note"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export const DeleteScreenshotModal = ({
  isOpen,
  toggle,
  handleDelete,
  isLoading,
}) => {
  const [reason, setReason] = useState("");

  const deleteScreenshot = () => {
    handleDelete(reason);
    toggleModal();
  };
  const debouncedHandleDeleteScreenshot = useCallback(() => {
    if (!isLoading) {
      deleteScreenshot();
    }
  }, [deleteScreenshot, isLoading]);
  const toggleModal = () => {
    toggle();
    setReason("");
  };
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} style={{ marginTop: `20vh` }}>
      <ModalBody style={{ padding: `30px` }}>
        <CommonText>
          Are you sure you want to delete this screenshot? <br /> The timeframe
          related to this screenshot will also be deleted.
        </CommonText>
        <InputWithLabelSection style={{ marginTop: "20px" }}>
          <InputLabel>
            Reason <RedStar>*</RedStar>
          </InputLabel>
          <InputTextArea
            id="reason-text-area"
            placeholder="Why do you want to delete the screenshot?"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            height="98px"
          />
        </InputWithLabelSection>
        <ModalButtonSection style={{ paddingTop: "30px" }}>
          <WhiteButton
            type="cancel"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </WhiteButton>
          <ModalDeleteButton
            disabled={!reason}
            padding={isLoading && "0 15px"}
            onClick={() => debouncedHandleDeleteScreenshot()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete"
            )}
          </ModalDeleteButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};
