import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { ColoredButton, WhiteButton } from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { useCallback } from "react";

const DeleteInvitationModal = ({ isOpen, toggle, handleDelete, isLoading }) => {
  const debouncedHandleDelete = useCallback(() => {
    if (!isLoading) {
      handleDelete();
    }
  }, [handleDelete, isLoading]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to delete this invitation?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? "0 15px" : "0 30px"}
            onClick={() => debouncedHandleDelete()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DeleteInvitationModal;
