import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import MembersTable from "./MembersTable";

import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";
import MemberDeleteModal from "./MemberDeleteModal";
import DeleteInvitationModal from "./DeleteInvitationModal";

import { setPathName } from "../../filterReducer";

import Plus from "../../assets/img/icons/plus_white.svg";

import SearchWithButton from "../../components/Search/SearchWithButton";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";
import SubscriptionCancellationModal from "./SubscriptionCancellationModal";

const Members = ({
  selectedOrganization,
  getMembersList,
  location,
  history,
  getInvitedMembersList,
  membersList,
  removeMember,
  deleteMemberInvitation,
  deleteMemberLoading,
  deleteInvitationLoading,
  inviteMember,
  isLoading,
  updateMemberwiseTimesheetApprovalSettings,
  invitedMemberList,
  filters,

  // pagination
  totalMembersCount,
  pageSize,
  reloadInvitedMembers,
  reloadMembers,
}) => {
  const dispatch = useDispatch();
  let queryParsed = queryString.parse(location?.search);

  const [memberList, setMemberList] = useState([]);
  const [invitedMembersList, setInvitedMembersList] = useState([]);
  const [tabSelect, setTabSelect] = useState(
    queryParsed?.tab === "invited"
      ? "invited"
      : filters?.pathname === "/user/members" && filters?.tabSelect
      ? filters?.tabSelect
      : "active"
  );

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] =
    useState(false);
  const [
    isSubscriptionCancelModalVisible,
    setIsSubscriptionCancelModalVisible,
  ] = useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState("");
  const [invitationDeleteId, setInvitationDeleteId] = useState("");
  const [roleValue, setRoleValue] = useState({
    value: "1",
    label: "All Roles",
  });
  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === "/user/members" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === "/user/members" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const RoleOptions = [
    { value: "1", label: "All Roles" },
    { value: "2", label: "Specific Roles" },
    { value: "3", label: "Test Project" },
  ];

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        currentSearchedTerm,
        tabSelect,
      })
    );
  }, [currentSearchedTerm, tabSelect]);

  const callGetMembersList = () => {
    if (selectedOrganization?.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      // Set the search term based on the tab selected
      if (currentSearchedTerm && currentSearchedTerm.trim()) {
        const searchKey = tabSelect === "invited" ? "email" : "name";
        payload[searchKey] = currentSearchedTerm.toLowerCase().trim();
      }

      tabSelect === "invited"
        ? getInvitedMembersList(payload)
        : getMembersList(payload);
    }
  };
  useEffect(() => {
    callGetMembersList();
  }, [currentPageNumber, currentSearchedTerm, tabSelect, selectedOrganization]);

  useEffect(() => {
    if (location.search) {
      let queryParsed = queryString.parse(location.search);
      if (queryParsed.tab && queryParsed.tab === "invited") {
        setTabSelect("invited");
      }
    }
  }, []);

  useEffect(() => {
    if (selectedOrganization?.id) {
      setCurrentPageNumber(1);
      if (tabSelect) {
        if (location?.search) {
          history?.replace({
            ...location,
            search: "",
          });
        }
        if (tabSelect === "invited") {
          setSearchTerm("");
          setCurrentSearchedTerm("");
        }
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (reloadInvitedMembers) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
        };
        getInvitedMembersList(payload);
      }
    }
  }, [reloadInvitedMembers]);
  useEffect(() => {
    if (reloadMembers) {
      callGetMembersList();
    }
  }, [reloadMembers]);

  useEffect(() => {
    if (invitedMemberList && invitedMemberList.length > 0) {
      setInvitedMembersList(invitedMemberList);
    } else if (invitedMemberList && invitedMemberList.length === 0) {
      setInvitedMembersList([]);
    }
  }, [invitedMemberList]);

  const onTabSwitch = (value) => {
    setCurrentPageNumber(1);
    setTabSelect(value);
    setSearchTerm("");
    setCurrentSearchedTerm("");
  };

  const handleSearchByName = () => {
    if (!selectedOrganization?.id) return;
    if (searchTerm?.trim()) {
      if (searchTerm.trim() !== currentSearchedTerm) {
        setCurrentPageNumber(1);
      }
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
  };
  const handleClearSearch = () => {
    if (!selectedOrganization?.id) return;
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setCurrentPageNumber(1);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const handleSubscriptionUpgrade = () => {
    history.push("/user/subscription");
  };

  const handleInviteButton = () => {
    const {
      subscription: { status },
    } = selectedOrganization;

    if (status != "cancelled") {
      history.push("/user/member-invitation");
      return;
    }
    setIsSubscriptionCancelModalVisible(!isSubscriptionCancelModalVisible);
  };

  const handleDeleteMember = () => {
    if (selectedOrganization && selectedOrganization.id && memberDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDeleteId,
        currentPageNumber,
      };
      removeMember(payload);
      setTimeout(() => {
        setIsDeleteModalVisible(!isDeleteModalVisible);
      }, 300);
    }
  };

  const handleDeleteInvitation = () => {
    if (selectedOrganization && selectedOrganization.id && invitationDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        invitation_id: invitationDeleteId,
        currentPageNumber,
      };
      deleteMemberInvitation(payload);
      setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
    }
  };

  const toggleDeleteModal = (memberId) => {
    if (!isDeleteModalVisible) {
      setMemberDeleteId(memberId);
    }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const toggleInvitationDeleteModal = (id) => {
    if (!isDeleteInvitationModalVisible) {
      setInvitationDeleteId(id);
    }
    setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const onRoleChange = (e) => {
    setRoleValue(e);
  };

  return (
    <div className="content">
      <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      />
      <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      />
      <SubscriptionCancellationModal
        isOpen={isSubscriptionCancelModalVisible}
        toggle={() =>
          setIsSubscriptionCancelModalVisible(!isSubscriptionCancelModalVisible)
        }
        handleUpgrade={handleSubscriptionUpgrade}
      />

      <PageTitle>Members</PageTitle>
      {selectedOrganization && selectedOrganization.role !== "member" && (
        <HeaderContainer>
          <TabLeftGrid
            columns={
              selectedOrganization.role !== "owner" &&
              selectedOrganization.role !== "admin"
                ? "300px"
                : "auto 85%"
            }
          >
            {selectedOrganization.plan &&
            selectedOrganization.plan.name !== "Solo" ? (
              <>
                {selectedOrganization.role === "owner" ||
                selectedOrganization.role === "admin" ? (
                  <InputWithLabelSection>
                    <FilterLabel>Type</FilterLabel>
                    <TabButtonContainer>
                      <TabButton
                        onClick={() => onTabSwitch("active")}
                        selected={tabSelect}
                        index={"active"}
                      >
                        Active
                      </TabButton>
                      <TabButton
                        onClick={() => onTabSwitch("invited")}
                        selected={tabSelect}
                        index={"invited"}
                      >
                        Invited
                      </TabButton>
                    </TabButtonContainer>
                  </InputWithLabelSection>
                ) : null}

                <SearchWithButton
                  itemName={tabSelect === "active" ? "Member" : "Invitation"}
                  searchTermName={
                    tabSelect === "active" ? "member name or email" : "email"
                  }
                  searchInput={searchTerm}
                  onInputChange={onSearchTermChange}
                  handleSearch={handleSearchByName}
                  handleClearSearch={handleClearSearch}
                />
              </>
            ) : null}

            {/* <InputWithLabelSection>
                <FilterLabel>Search Member</FilterLabel>
                <InputField
                  type="text"
                  placeholder={`Search member by ${
                    tabSelect === "active" ? "name" : "email"
                  }...`}
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                />
              </InputWithLabelSection> */}
          </TabLeftGrid>

          {selectedOrganization &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") ? (
            <ColoredButtonWithIcon onClick={handleInviteButton}>
              <ButtonIcon src={Plus} alt="" />
              Invite Member
            </ColoredButtonWithIcon>
          ) : null}
        </HeaderContainer>
      )}

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName="members"
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <MembersTable
        selectedOrganization={selectedOrganization}
        tabSelect={tabSelect}
        membersList={memberList}
        invitedMembersList={invitedMembersList}
        inviteMember={inviteMember}
        history={history}
        toggleDeleteModal={toggleDeleteModal}
        toggleInvitationDeleteModal={toggleInvitationDeleteModal}
        searchTerm={searchTerm}
        isLoading={isLoading}
        updateMemberwiseTimesheetApprovalSettings={
          updateMemberwiseTimesheetApprovalSettings
        }
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Members;
